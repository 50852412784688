import * as React from 'react';
import { Col, Row, Card } from 'react-bootstrap';

export default function Bookmark_header() {
    return (
        <Row className='dt_header justify-content-center'style={{paddingTop:"3em"}}>
            <Col sm={4}>
                <img src='https://global-uploads.webflow.com/5e3ce2ec7f6e53c045fe7cfa/603dd9a40b31de31e9714285_Frame-147.png' style={{ height: '200px', width: '200px' }}></img>
            </Col>

            <Col sm={8}>
                <h1 style={{ textAlign: 'center', fontSize: '22px', paddingTop: '1em' }}>My Bookmarks</h1>
                <Card body style={{ borderRadius: '30px', marginTop: '1em', backgroundColor: '#FFC363', fontSize: '13px', fontFamily: 'Poppins' }}>
                    It's time to set your goal! Pick one of your shortlisted bookmarked items as your career goal.
                    <p style={{ paddingBottom: 0 }}>Doesn't have what you want? Click <a href="/career-domains">here</a> to explore more!</p>
                </Card>
            </Col>
        </Row>
    )
}