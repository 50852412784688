import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Link from '@mui/material/Link';
import Typography from "@material-ui/core/Typography";
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';

function createData(Time, Lesson, Link) {
  return { Time, Lesson, Link };
}



const rows = [
  createData('08.30', <Box><Typography variant="body2" align="left" gutterBottom>Chemistry lesson</Typography><Typography variant="caption"  align="left" >Lecturer: Mr Samuel  @Google Meet</Typography></Box>, <Link href="https://igsloto.ca7dev.url3.net/loto/#/course/139" target="_blank">Link</Link> ),
  createData('10.00', <Box><Typography variant="body2" align="left" gutterBottom>Chinese lesson</Typography><Typography variant="caption"  align="left" >Lecturer: Mr Wang  @Google Meet</Typography></Box>, <Link href="https://onesquareminesweeper.com/" target="blank">Link</Link> ),
  createData('11.30', <Box><Typography variant="body2" align="left" gutterBottom>Mathematics lesson</Typography><Typography variant="caption"  align="left" >Lecturer:Mr Ray  @Google Meet</Typography></Box>, <Link href="#">Link</Link> ),
  createData('13.30', <Box><Typography variant="body2" align="left" gutterBottom>Biology lesson</Typography><Typography variant="caption"  align="left" >Lecturer: Mr Biology  @Google Meet</Typography></Box>,<Link href="#">Link</Link> ),
  createData('15.00', <Box><Typography variant="body2" align="left" gutterBottom>English lesson</Typography><Typography variant="caption"  align="left" >Lecturer: Mr English  @Google Meet</Typography></Box>,<Link href="#">Link</Link> ),
];

export default function timetable() {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead> 
          <TableRow>
            <TableCell  align="left">Time</TableCell>
            <TableCell>Lesson</TableCell>
            <TableCell align="right">Link</TableCell>

          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.Time}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.Time}
              </TableCell>
              <TableCell >{row.Lesson}</TableCell>
              <TableCell align="right">{row.Link}</TableCell>
       
       
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
