import React, { Component } from 'react';
import { Container } from 'react-bootstrap';
import ResponsiveAppBar from '../../components/navbar';
import LinkTab from '../../components/menu_bar/dt-menu';
import AppFooter from '../../components/footer';

import './DesignThinking.css';
import ProjectOutline from '../../assets/DT-ProjectOutline.png'


class DTProjectOutline extends Component {
    render() {
        return (
            <Container fluid mx="3px" id="courses" style={{ width: "100%", margin: 0, padding: 0 }}>
                <ResponsiveAppBar />

                <div className="flex-container align-content-sm-stretch" id="dt-header">
                    <h1 style={{ fontSize: "25px", paddingTop: "12px", textAlign: "center" }}>Design Thinking</h1>

                    <div className="row dt-subtitle"
                        style={{ fontSize: "19px", fontFamily: "Open Sans", background: "transparent" }}>
                        <p style={{ textAlign: "center", fontSize: "18px" }}>Speacially brought to you by Invictus</p>
                    </div>

                    <LinkTab />
                </div>

                <div className="container">
                    <div className="overview" style={{ margin: "1em" }}>
                        <h1 style={{ textAlign: "left", alignContent: "left", fontSize: "20px", marginLeft:"5em" }}>
                            Timeline
                        </h1>
                    </div>

                    <div className="dt-pic" style={{ alignSelf: "center", justifyContent: "center", display: "flex" }}>
                        <img className="design-thinking" src={ProjectOutline} ></img>
                    </div>
                </div>
                <AppFooter />
            </Container>
        )
    }
}

export default DTProjectOutline;