import * as React from 'react';
import { MDBCard, MDBCardImage, MDBCardBody, MDBCardTitle, MDBCardText, MDBRow, MDBCol, MDBBtn } from 'mdb-react-ui-kit';
import { Container, Button } from 'react-bootstrap';
import { Checkbox } from '@mui/material';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import BookmarkIcon from '@mui/icons-material/Bookmark';

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
const BASE_API_URL = `https://api.invictus.com.kh/api`;



export default function RelevantCareers() {
    return (
        <Container>
            <div className="relevant_careers" style={{ marginTop: '2em' }}>
                <h5 style={{ fontSize: '17px' }}>
                    Here are some of the relavent careers that you might be interested in!
                </h5>
            </div>
            <MDBRow className='row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 g-4 transform-container' style={{ justifyContent: 'center' }}>
                <MDBCol>
                    <MDBCard className='h-90'>
                        <MDBCardImage
                            className="career-img"
                            src='https://api.invictus.com.kh/api/django_static/img/careers/401.png'
                            // src={BASE_API_URL + career.image}
                            alt='...'
                            position='top'
                            style={{ width: 'auto', height: "40%", objectFit: "contain" }}
                        />
                        <MDBCardBody>
                            <MDBRow className="course-card-title">
                                <MDBCol className="col-9">
                                    <MDBCardTitle>Robotics & Automation Engineering</MDBCardTitle>
                                </MDBCol>

                                <MDBCol className="col-3">
                                    <Checkbox
                                        {...label}
                                        icon={<BookmarkBorderIcon />}
                                        checkedIcon={<BookmarkIcon />}
                                    // value={career.id}
                                    // checked={career.bookmarked}
                                    // onChange={(event) => addCareerBookmark(event, career.id)}
                                    />
                                </MDBCol>
                            </MDBRow>

                            <MDBCardText style={{ color: "black", fontSize: "11px" }}>
                                The combination of electronics, electromagnetism, electrical systems, computer science and control systems
                                to the deisgn, realization of robots and automomous machinery.
                            </MDBCardText>

                            <MDBRow style={{ display: "flex", justifyContent: "center" }}>
                                <div className="col-6 card__actions">
                                    <Button href={`/career-domains/42`} className="btn btn-color-details" style={{ fontSize: "10px", justifyContent: 'center', display: 'flex' }}>Details</Button>
                                </div>
                            </MDBRow>
                        </MDBCardBody>
                    </MDBCard>
                </MDBCol>

                <MDBCol>
                    <MDBCard className='h-90'>
                        <MDBCardImage
                            className="career-img"
                            src='https://api.invictus.com.kh/api/django_static/img/careers/14.png'
                            // src={BASE_API_URL + career.image}
                            alt='...'
                            position='top'
                            style={{ width: 'auto', height: "40%", objectFit: "contain" }}
                        />
                        <MDBCardBody>
                            <MDBRow className="course-card-title">
                                <MDBCol className="col-9">
                                    <MDBCardTitle>Network Engineering</MDBCardTitle>
                                </MDBCol>

                                <MDBCol className="col-3">
                                    <Checkbox
                                        {...label}
                                        icon={<BookmarkBorderIcon />}
                                        checkedIcon={<BookmarkIcon />}
                                    // value={career.id}
                                    // checked={career.bookmarked}
                                    // onChange={(event) => addCareerBookmark(event, career.id)}
                                    />
                                </MDBCol>
                            </MDBRow>

                            <MDBCardText style={{ color: "black", fontSize: "11px" }}>
                                Network Engineering includes jobs whose roles involve designing and
                                building data communication networks that range from Local Area Networks,
                                Wide Area Networks, Intranets, Peer-to-Peer, and the Internet.
                            </MDBCardText>

                            <MDBRow style={{ display: "flex", justifyContent: "center" }}>
                                <div className="col-6 card__actions">
                                    <Button href={`/career-domains/11`} className="btn btn-color-details" style={{ fontSize: "10px", justifyContent: 'center', display: 'flex' }}>Details</Button>
                                </div>
                            </MDBRow>
                        </MDBCardBody>
                    </MDBCard>
                </MDBCol>

                <MDBCol>
                    <MDBCard className='h-90'>
                        <MDBCardImage
                            className="career-img"
                            src='https://api.invictus.com.kh/api/django_static/img/careers/13.png'
                            // src={BASE_API_URL + career.image}
                            alt='...'
                            position='top'
                            style={{ width: 'auto', height: "40%", objectFit: "contain" }}
                        />
                        <MDBCardBody>
                            <MDBRow className="course-card-title">
                                <MDBCol className="col-9">
                                    <MDBCardTitle>Mobile App Development</MDBCardTitle>
                                </MDBCol>

                                <MDBCol className="col-3">
                                    <Checkbox
                                        {...label}
                                        icon={<BookmarkBorderIcon />}
                                        checkedIcon={<BookmarkIcon />}
                                    // value={career.id}
                                    // checked={career.bookmarked}
                                    // onChange={(event) => addCareerBookmark(event, career.id)}
                                    />
                                </MDBCol>
                            </MDBRow>

                            <MDBCardText style={{ color: "black", fontSize: "11px" }}>
                                Mobile Application Development involves the design, implementation
                                and user testing of applications on mobile devices such as mobile phones, tablets or
                                other handheld devices.
                            </MDBCardText>

                            <MDBRow style={{ display: "flex", justifyContent: "center" }}>
                                <div className="col-6 card__actions">
                                    <Button href={`/career-domains/3`} className="btn btn-color-details" style={{ fontSize: "10px", justifyContent: 'center', display: 'flex' }}>Details</Button>
                                </div>
                            </MDBRow>
                        </MDBCardBody>
                    </MDBCard>
                </MDBCol>
            </MDBRow>

            <div className="btn-more" style={{ justifyContent: 'center', display: 'flex' }}>
                <Button variant="outline" href={`/career-domains`} style={{ justifyContent: 'center', display: 'flex', margin: '2em', fontSize: '13px', borderColor: '#053e85' }}>
                    See More
                </Button>
            </div>
        </Container>
    )
}
