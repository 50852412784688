import React, { Component } from 'react';
import Navbar from 'react-bootstrap/Navbar'

import './footer.css';

class AppFooter extends Component {
    render() {
        return (
            <div className=" footer wrapper flex-grow-1" style={{ padding: 0, paddingTop: "30px", bottom: 0 }}>
                <Navbar className="navbar-dark"
                    style={{ backgroundColor: "#1A2E5A", textAlign: "center", fontFamily: "Poppins", fontSize: "18px", marginTop: "5em", bottom: 0 }}>
                    <Navbar.Brand style={{ fontSize: "13px" }}>  Copyright © 2022 RSCAREA. All Rights Reserved.</Navbar.Brand>
                </Navbar>
            </div>
        )
    }
}

export default AppFooter;