import React, { Component } from 'react';
import { Container } from 'react-bootstrap';

// import './UndergradDetails.css'
import ResponsiveAppBar from '../../components/navbar'
import AppFooter from '../../components/footer';
import CareerDetail from '../../components/cards/CareerDetail';



// BASE_API_URL = 'https://api.invictus.com.kh/api'

class CareerDetails extends Component {
    render() {
        return (
            <Container fluid mx="3px" id="courses" style={{ width: "100%", margin: 0, padding: 0 }}>
                <ResponsiveAppBar />
                <CareerDetail />
                <AppFooter />
            </Container>
        )
    }
}

export default CareerDetails;