import axios from "axios";
import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { Container, Row, Col, Card } from "react-bootstrap";
import { Select, Grid, Modal, Typography, Box, Dialog, DialogActions, DialogTitle, DialogContent, DialogContentText } from "@mui/material";
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import DownloadDoneSharpIcon from '@mui/icons-material/DownloadDoneSharp';
import FormHelperText from '@mui/material/FormHelperText';

import Welcome from '../../assets/undraw_outer_space_re_u9vd.svg'
import JobMirror from '../../assets/undraw_career_progress_ivdb.svg'

const riasecOptions = [
    { value: 1, label: 'Realistic (R)' },
    { value: 2, label: 'Investigative (I)' },
    { value: 3, label: 'Artistic (A)' },
    { value: 4, label: 'Social (S)' },
    { value: 5, label: 'Enterprising (E)' },
    { value: 6, label: 'Conventional (C)' },
];


const RiasecDropdown = (props) => {
    const { label, keyPrefix, value, setSelected, allOptions, remainingOptions, handleChange } = props

    let valueAndRemainingOptions = remainingOptions

    if (value != "") {
        const val = allOptions.filter(o => o.value === value)
        valueAndRemainingOptions = [val[0], ...remainingOptions]
    }

    return (
        <FormControl fullWidth required size="small">
            <InputLabel id="demo-simple-select-label" size="small">{label}</InputLabel>
            <Select
                value={value}
                label={label}
                onChange={(event) => handleChange(event, setSelected, keyPrefix)}
                required={true}
                size="small"
            >
                <MenuItem value="" style={{ fontSize: "13px" }}><em>None</em></MenuItem>
                {valueAndRemainingOptions.map((option) => {
                    return <MenuItem key={keyPrefix + option.value} value={option.value} style={{ fontSize: "13px" }}>{option.label}</MenuItem>
                })}
            </Select>
            <FormHelperText>Required</FormHelperText>
            <div className="invalid-feedback">Example invalid select feedback</div>
        </FormControl>
    )

}

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};


class Onboarding extends Component {
    constructor() {
        super();
        this.state = {
            csrf: "",
            fullName: "",
            riasec_1: "",
            riasec_2: "",
            riasec_3: "",
            options: riasecOptions,
            remainingOptions: riasecOptions,
            selected: [],
            onBoarded: false,
            openModal: false,

        }
    };

    setRiasec_1 = (data) => {
        this.setState({ riasec_1: data })
    }

    setRiasec_2 = (data) => {
        this.setState({ riasec_2: data })
    }

    setRiasec_3 = (data) => {
        this.setState({ riasec_3: data })
    }

    setSelected = (data) => {
        this.setState({ selected: data })
    }

    setOptions = (data) => {
        this.setState({ options: data })
    }

    setOnBoarded = (data) => {
        this.setState({ onBoarded: data }, () => {
            console.log(this.state.onBoarded)
        })
    }

    setOpenModal = (data) => {
        this.setState({ openModal: data })
    }

    handleOpenModal = (data) => {
        this.setOpenModal(true)
    }

    handleCloseModal = (data) => {
        this.setOpenModal(false)
    }

    handleChange = (value, dropdownId) => {
        const remainingOptions = this.state.options.filter(
            (o) => o.label !== value && o.value !== dropdownId
        );
        // free up value previously selected in that dropdown
        const optionToFreeUp = this.state.options.find((o) => o.value === dropdownId);
        if (optionToFreeUp.value !== undefined) {
            optionToFreeUp.value = undefined;
            remainingOptions.push(optionToFreeUp);

        }
        console.log("remaining options", remainingOptions)
        console.log("optionstofreeup", optionToFreeUp)

        this.setOptions([...remainingOptions, { value: dropdownId, label: value }])
    };

    renderDropdown = (dropdownId) => {
        console.log("dropdown id", dropdownId)
        return (
            <div>
                <select
                    onChange={(e) => this.handleChange(e.target.value, dropdownId)}
                    value={
                        (this.state.options.find((o) => o.value === dropdownId) || {
                            label: "none",
                        }).label
                    }>
                    {this.renderOptions(dropdownId)}
                </select>
            </div>
        );
    };

    renderOptions = (dropdownId) => {
        this.state.options
            .filter((o) => o.value === dropdownId || o.value === undefined)
            .map((o) => <option value={o.label}>{o.label}</option>);
    }

    setCSRF = (data) => {
        this.setState({ csrf: data })
    }

    componentDidMount() {
        this.getSession();
    }

    fetchMe = () => {
        axios.get(`https://api.invictus.com.kh/api/me`,
            { withCredentials: true })
            .then(response => {
                console.log(response.data)
                return response.data
            })
            .catch(error => {
                console.error('Error', error);
            });
    }

    setRemainingOptions = (data) => {
        this.setState({ remainingOptions: data })
    }

    getCSRF = () => {
        fetch("https://api.invictus.com.kh/api/csrf/", {
            credentials: "include",
        })
            .then((res) => {
                let csrfToken = res.headers.get("X-CSRFToken");
                this.setState({ csrf: csrfToken });
                console.log("csrfToken: ", csrfToken);
            })
            .catch((err) => {
                console.log(err);
            });
    }

    // check if user is authenticated 
    getSession = () => {
        fetch("https://api.invictus.com.kh/api/session/", {
            credentials: "include",
        })
            .then((res) => res.json())
            .then((data) => {
                console.log(data);
                if (data.isAuthenticated) {
                    this.setState({ isAuthenticated: true });
                    this.getCSRF();
                } else {
                    this.setState({ isAuthenticated: false });
                    window.location = "/login/"
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }

    handleChange1 = (event, setRiasec, keyPrefix) => {
        let position = parseInt(keyPrefix.split("_")[1])
        setRiasec(event.target.value)

        let newSelected = [this.state.riasec_1, this.state.riasec_2, this.state.riasec_3]
        newSelected[position - 1] = event.target.value

        const remainingOptions = []
        this.state.options.forEach(option => {
            if (!newSelected.includes(option.value)) {
                remainingOptions.push(option)
            }
        })

        this.setSelected(newSelected)
        this.setRemainingOptions(remainingOptions)
    }

    handleSubmit = () => {
        const user_riasec = {
            profile: {
                riasec_1: this.state.riasec_1,
                riasec_2: this.state.riasec_2,
                riasec_3: this.state.riasec_3,
            },
            onboarded: true
        }

        const config = {
            headers: { "X-CSRFToken": this.state.csrf },
            withCredentials: true,
        }

        console.log("user: ", user_riasec.profile.riasec_1.length)
        if (user_riasec.profile.riasec_1.length < 1 || user_riasec.profile.riasec_2.length < 1 || user_riasec.profile.riasec_3.length < 1) {
            this.handleOpenModal()
            return
        }

        //console.log("user_riasec: ", user_riasec)
        //console.log("CSRF: ", this.state.csrf)
        axios.put('https://api.invictus.com.kh/api/me', user_riasec, config)
            .then(res => {
                console.log("res: ", res);
                console.log("res.data: ", res.data);
                // this.setOnBoarded(true)
                window.location = "/career-domains"
                // console.log("obboarded: ", this.state.onBoarded)
                // this.updateOnboard();
            })
            .catch((err) => {
                console.log(err)
            })

        // const data = {
        //     onboarded: this.state.onBorded
        // }

        // axios.put('https://api.invictus.com.kh/api/me', data, config)
        //     .then(res => {
        //         console.log(res.data)
        //     })
        //     .catch((err) => {
        //         console.log(err)
        //     })
    }

    updateOnboard = () => {
        const data = {
            onboarded: this.state.onBoarded
        }

        const config = {
            headers: { "X-CSRFToken": this.state.csrf },
            withCredentials: true,
        }

        axios.put('https://api.invictus.com.kh/api/me', data, config)
            .then(res => {
                console.log(res.data)
                console.log("data: ", data)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    render() {
        return (
            <Container id="onBoarding">
                <Row className="onboardHeader" style={{ marginTop: "2.5em" }}>
                    <Col xs="12" md="4" style={{ paddingLeft: "8em", textAlign: "center" }}>
                        <img src={Welcome} className="header-onboard" id="welcomeHeaderPic" alt="logo"
                            style={{ width: "150px", height: "100px", justifyContent: "center" }} />
                    </Col>

                    <Col xs="12" md="8" >
                        <div className="welcome-msg">
                            <h1 style={{ fontSize: "20px" }}>Welcome!</h1>
                            <p>Before we begin, please answer several questions about yourself and complete your profile!</p>
                        </div>
                    </Col>
                </Row>

                <Row className="description" style={{ marginTop: "1.5em" }}>
                    <Col md="4" style={{ paddingLeft: "3em", textAlign: "center" }}>
                        <img src={JobMirror} className="header-onboard  animate__animated animate__fadeIn " id="welcomeHeaderPic" alt="logo"
                            style={{ width: "300px", height: "300px", justifyContent: "center", marginRight: 0 }} />
                    </Col>
                    <Col md="8" xs="12" className="description-riasec" style={{ paddingRight: "1em", paddingTop: "1em" }}>
                        <h3 className="animate__animated animate__zoomIn" style={{ textAlign: "center", fontSize: "17px" }}> Step 1: Understanding Yourself! </h3>
                        <h4 style={{ fontWeight: "normal", fontSize: "16px", paddingTop: "0.5em", textAlign: "center" }}>Finding your Right Career and Courses with Holland Code Assessment (RIASEC) now!</h4>
                        <h5 style={{ paddingTop: "0.5em", fontWeight: "lighter", textAlign: "end", fontSize: "13px" }}>Click
                            <a href="https://openpsychometrics.org/tests/RIASEC/" target="_blank" className="text-decoration-none"> here </a>
                            to take the assessment! </h5>
                        <Card style={{ marginTop: "1em", height: "50%", padding: 0 }}>
                            <Card.Body>
                                <Card.Text stye={{ fontSize: "10px" }}>
                                    RIASEC is an acronym that stands for six dimensions of career interest types:
                                    Realistic (R), Investigative (I), Artistic (A), Social (S), Enterprising (E) and Conventional (C)
                                </Card.Text>
                                <Button variant="primary" href="https://www.careerkey.org/fit/personality/holland-code-assessment-riasec" target="_blank" style={{ float: "right", fontSize: "10px", padding: 0 }}>Learn More</Button>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>

                <Row className="riasecDropdowns">
                    <div className="explain-riasec">
                        <p style={{ textAlign: "center", fontSize: "12px" }}>
                            Select the TOP 3 letters identified as your interest. Place them in the correct order (in terms of Highest Score) to get your recommended careers and undergraduate programmes for further exploration!
                        </p>
                        <p style={{ fontSize: "12px" }}> Alternatively, you can get your <strong>BOLDED </strong>
                            RIASEC Result Code under "Suitable Careers" section on the bottom of the assessment page </p>
                    </div>

                    <Grid container spacing={2} >
                        <Grid item xs={12} md={4} size="small">
                            <RiasecDropdown
                                className="riasecDropdown1"
                                label="RIASEC Code 1"
                                keyPrefix="riasec_1_"
                                value={this.state.riasec_1}
                                setSelected={this.setRiasec_1}
                                allOptions={this.state.options}
                                remainingOptions={this.state.remainingOptions}
                                handleChange={this.handleChange1}
                                style={{ backgroundColor: "#1A3061", fontSize: "9px" }}
                                size="small"
                            />
                        </Grid>

                        <Grid item xs={12} md={4}>
                            <RiasecDropdown
                                id="riasecDropdown2"
                                label="RIASEC Code 2"
                                keyPrefix="riasec_2_"
                                value={this.state.riasec_2}
                                setSelected={this.setRiasec_2}
                                allOptions={this.state.options}
                                remainingOptions={this.state.remainingOptions}
                                handleChange={this.handleChange1}
                                style={{ fontSize: "10px" }}
                                size="small"
                            />
                        </Grid>

                        <Grid item xs={12} md={4}>
                            <RiasecDropdown
                                id="riasecDropdown3"
                                label="RIASEC Code 3"
                                keyPrefix="riasec_3_"
                                value={this.state.riasec_3}
                                setSelected={this.setRiasec_3}
                                allOptions={this.state.options}
                                remainingOptions={this.state.remainingOptions}
                                handleChange={this.handleChange1}
                                style={{ font: "10px" }}
                                size="small"
                            />
                        </Grid>
                    </Grid>
                </Row>

                {/* <Row> */}
                <Button className="riasec-submit" variant="outlined" type="submit" startIcon={<DownloadDoneSharpIcon />}
                    onClick={() => { this.handleSubmit() }}
                    style={{ fontSize: "15px", backgroundColor: "#1A2E5A", color: "#F9A826", width: "20%", marginTop: "3em", float: "right", marginBottom: "2em" }}>
                    Done
                </Button>
                {/* </Row> */}
                <Dialog
                    open={this.state.openModal}
                    onClose={this.handleCloseModal}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <DialogTitle id="alert-dialog-title" style={{ textAlign: "center" }}>
                        {"RIASEC Required"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Please ensure that you have filled up all three RIASEC codes before submitting
                        </DialogContentText>
                    </DialogContent>

                    <DialogActions>
                        <Button onClick={this.handleCloseModal} autoFocus>
                            Okay
                        </Button>
                    </DialogActions>
                </Dialog>
            </Container >

        );
    }
}

export default Onboarding;