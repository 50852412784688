import React, { Component, useEffect, useState } from 'react';
import axios from "axios";
import { useParams, useHistory } from 'react-router-dom';
import { Container, Badge, Card } from 'react-bootstrap';
import { MDBBadge, MDBContainer, MDBChip } from "mdbreact";
import { MDBRow, MDBCol } from 'mdb-react-ui-kit';
import RelevantUndergrad from './RelevantUndergrad';

import './ProgrammeDetails.css';


const url = 'https://api.invictus.com.kh/api'
const label = { inputProps: { 'aria-label': 'Checkbox demo' } };


const CareerDetail = () => {
    const [career_detail, setCareerDetail] = useState([])
    const [career_skills, setCareerSkills] = useState([])
    const [career_jobs, setCareerJobs] = useState([])
    const history = useHistory();
    let { id } = useParams();

    const config = {
        withCredentials: true,
    }

    const fetchCareerDetail = () => {
        console.log(`inside fetch career detail- id: ${id}`)
        axios.get(`${url}/career-domains/${id}`, config)
            .then(response => {
                console.log("response.data", response.data)
                setCareerDetail(response.data)
                setCareerSkills(response.data.skills)
                setCareerJobs(response.data.job_titles)
                console.log("career details: ", response.data)
            });
    }

    useEffect(() => {
        fetchCareerDetail();
    }, []);

    return (
        <>
            <div className="flex-container" id="course-detail-header">
                <h1 style={{ fontSize: "25px", textAlign: "center" }}>{career_detail.display_name}</h1>
            </div>

            <div className="container">
                <button className="btn" id="back-button" style={{ fontSize: "14px", marginTop: "20px", paddingLeft: 0 }}
                    onClick={() => history.goBack()}>
                    <i className="bi bi-arrow-return-left fa-lg"></i> Back
                </button>

                <div className="details" id="basicInformation">
                    <h2 style={{ fontSize: "19px" }}>Basic Information</h2>
                    <MDBRow className="details-header">
                        <MDBCol className="col-12 col-md-4 detail-title">
                            <h5>Career Domain Title</h5>
                        </MDBCol>
                        <MDBCol className="col-12 col-md-8 detail">
                            <p> {career_detail.display_name}</p>
                        </MDBCol>
                    </MDBRow>

                    <MDBRow className="details-header">
                        <MDBCol className="col-12 col-md-4 detail-title">
                            <h5>Career Domain Description</h5>
                        </MDBCol>
                        <MDBCol className="col-12 col-md-8 detail">
                            <p>{career_detail.description}</p>
                        </MDBCol>
                    </MDBRow>

                    <MDBRow className="details-header">
                        <MDBCol className="col-12 col-md-4 detail-title">
                            <h5>Summary</h5>
                        </MDBCol>
                        <MDBCol className="col-12 col-md-8 detail">
                            <p>{career_detail.summary}</p>
                        </MDBCol>
                    </MDBRow>
                </div>

                <div className="details" id="interest">
                    <h2 style={{ fontSize: "19px" }}>Interest Codes</h2>
                    <p>
                        <Badge className="btn btn-primary" bg="info" fontSize="14px">
                            {career_detail?.interest_1?.name}
                        </Badge>
                        <Badge className="btn btn-primary" bg="info" fontSize="14px">
                            {career_detail?.interest_2?.name}
                        </Badge>
                        <Badge className="btn btn-primary" bg="info" fontSize="14px">
                            {career_detail?.interest_3?.name}
                        </Badge>
                    </p>
                </div>


                <div className="details" id="admission">
                    <h2 style={{ fontSize: "19px" }}>Skills</h2>
                    <MDBRow className="details-header">
                        <MDBContainer>
                            {career_skills.map(skill => (
                                <h7>
                                    <Badge pill className='mx-2' bg="warning" text="dark" style={{ marginTop: "1em", paddingTop: "0.7em", paddingBottom: "0.7em", paddingLeft: "0.5em", paddingRight: "0.8em" }}>
                                        {skill}
                                    </Badge>
                                </h7>
                            ))}

                        </MDBContainer>
                    </MDBRow>
                </div>

                <div className="details" id="employment">
                    <h2 style={{ fontSize: "19px" }}>Job Titles</h2>
                    <MDBRow className="details-header">
                        <MDBCol className="col-12 col-md-8">
                            {career_jobs.map(title => (
                                <h7>
                                    <Badge className='mx-2 jobTitles'
                                        style={{ marginTop: "1em", paddingTop: "1em", paddingBottom: "1em", paddingLeft: "0.5em", paddingRight: "0.8em", backgroundColor: "#1a3061", color: "rgb(249, 168, 38)" }}>
                                        {title}
                                    </Badge>
                                </h7>
                            ))}
                        </MDBCol>
                    </MDBRow>
                </div>
                <RelevantUndergrad />
            </div>
        </>
    )

}

export default CareerDetail;
