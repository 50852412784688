import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Link from '@mui/material/Link';
import Typography from "@material-ui/core/Typography";
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';

function createData(Time, Lesson, Link) {
  return { Time, Lesson, Link };
}



const rows = [
  createData('12.00', <Box><Typography variant="body2" align="left" gutterBottom>CMEK - 第十二课 - 水果和蔬菜</Typography><Typography variant="caption"  align="left" >Lecturer: @Dudu</Typography></Box>, <Link href="#">Link</Link> ),
  createData('14.00', <Box><Typography variant="body2" align="left" gutterBottom>自习课 （故事书 – 跳跳怎么吃到苹果） </Typography><Typography variant="caption"  align="left" >Lecturer: @Dudu</Typography></Box>, <Link href="https://dd.ca4dev.url3.net/ddweb/#/book/577/_9E2B65" target="_blank">Link</Link> ),
  createData('16.00', <Box><Typography variant="body2" align="left" gutterBottom>课外活动 - 水果贴纸本</Typography><Typography variant="caption"  align="left" >Lecturer: @Dudu</Typography></Box>, <Link href="https://dd.ca4dev.url3.net/ddsticker/#/book/20" target="_blank">Link</Link> ),
  createData('17.00', <Box><Typography variant="body2" align="left" gutterBottom>Physics lesson</Typography><Typography variant="caption"  align="left" >Lecturer: Mr Babaram  @Google Meet</Typography></Box>,<Link href="#">Link</Link> ),
  createData('18.30', <Box><Typography variant="body2" align="left" gutterBottom>English lesson</Typography><Typography variant="caption"  align="left" >Lecturer: Mr English  @Google Meet</Typography></Box>,<Link href="#">Link</Link> ),
];

export default function timetable2() {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 300 }} aria-label="simple table">
        <TableHead> 
          <TableRow>
            <TableCell  align="left">Time</TableCell>
            <TableCell>Lesson</TableCell>
            <TableCell align="right">Link</TableCell>

          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.Time}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.Time}
              </TableCell>
              <TableCell >{row.Lesson}</TableCell>
              <TableCell align="right">{row.Link}</TableCell>
       
       
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
