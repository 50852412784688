import React, { useState, useEffect } from 'react';
import axios from 'axios';


const GetAllEvents = () => {

    const [events, setEvents] = useState([]);

    useEffect(() => {
        fetchEvents();
    }, []);

    const fetchEvents = () => {
        axios
            .get('https://api.invictus.com.kh/api/events/')
            .then((response) => {
                console.log(response);
                setEvents(response.data.results);
            })
            .catch((err) => {
                console.log(err);
            });
    };
    return (

        <div className="Eventlistholder">
            {events.map((event) => (
                <div className="card  mx-auto  mt-4" style={{ maxWidth: "800px" }}>

                    <div className="row no-gutters">
                        <div className="col-md-5 event-img-container" style={{ objectFit: "fill" }}>
                            <img src={`${event.image_url}`} class="card-img" alt="Eventimg"></img>
                        </div>
                        <div className="col-md-7">
                            <a href={`${event.info_url}`} class="stretched-link">
                                <div className="card-body">
                                    <h5 className="card-title" style={{ fontSize: "16px" }}>{event.title}</h5>
                                    <p className="card-text" style={{ fontSize: "12px" }}>{event.details}</p>
                                    <p className="card-text" style={{ fontSize: "12px" }}>Hosted by: {event.organizer} </p>
                                    <p className="card-text" style={{ fontSize: "11px" }}>Link: {event.info_url}</p>
                                </div>
                            </a>
                        </div>
                    </div>

                </div>

            ))};
        </div>

    );
};


export default GetAllEvents;