import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Button from '@mui/material/Button';
import axios from "axios";
import ResponsiveAppBar from '../../components/navbar';
import AppFooter from '../../components/footer';

import CareerListings from '../../components/cards/CareerListings';
import SearchCareer from '../../components/filters/searchCareer';


class Careers extends Component {
    constructor(props) {
        super(props);
        this.state = {
            search: "",
            careers: [],
            page: 1,
            pageCount: 0,
            total: null,
            loading: false,
            riasec_1: "",
            riasec_2: "",
            riasec_3: "",
        };

        this.setCareers = this.setCareers.bind(this);
    }

    componentDidMount() {
        this.fetchCareers();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.page !== this.state.page) {
            //console.log("page", this.state.page)
            this.fetchCareers();
        }
    }

    setSearchQuery = (data) => {
        // console.log("search: ",data.target.value)
        this.setState({ search: data.target.value })
    }

    setCareers = (data) => {
        //console.log("data from setCourses_: ", data)
        this.setState({ careers: data })
    }

    setRiasec_1 = (data) => {
        this.setState({ riasec_1: data })
    }

    setRiasec_2 = (data) => {
        this.setState({ riasec_2: data })
    }

    setRiasec_3 = (data) => {
        this.setState({ riasec_3: data })
    }

    setPage = (data) => {
        this.setState({ page: data })
    }

    setPageCount = (data) => {
        this.setState({ pageCount: data })
    }

    setTotal = (data) => {
        this.setState({ total: data })
    }

    setLoading = (data) => {
        this.setState({ loading: data })
    }

    getUrlQuery = () => {
        return `https://api.invictus.com.kh/api/career-domains?search=${this.state.search}&page=${this.state.page}`;
    }


    resetForm = () => {
        this.setState({
            ...this.state,
            search: "",
            page: 1
        }, () => {
            this.fetchCareers();
        })
    }

    handleSubmit = () => {
        this.setState({
            ...this.state,
            page: 1,
        }, () => {
            this.fetchCareers();
        })
    }

    fetchMe = () => {
        axios.get(`https://api.invictus.com.kh/api/me`,
            { withCredentials: true })
            .then(response => {
                this.setRiasec_1(response.data.profile.interest_1.name)
                this.setRiasec_2(response.data.profile.interest_2.name)
                this.setRiasec_3(response.data.profile.interest_3.name)
                console.log(response.data)
                return response.data
            })
            .catch(error => {
                console.error('Error', error);
            });
    }

    fetchCareers = () => {
        const URL = this.getUrlQuery();
        axios.get(URL, { withCredentials: true })
            .then(response => {
                this.setCareers(response.data.results)
                this.fetchMe();
                console.log("response.data.results: ", response.data.results)
                let contentRange = response.headers["content-range"]
                let total = contentRange.split("/")[1]
                let length = response.data.page_size
                const total_page = Math.ceil(total / length)
                this.setPageCount(total_page)
                this.setTotal(contentRange.split("/")[1])
            })
            .catch(error =>
                console.error('Error', error));
    }

    render() {
        return (
            <Container fluid mx="3px" id="courses" style={{ width: "100%", margin: 0, padding: 0 }}>
                <ResponsiveAppBar />

                <div className="flex-container" id="course-header" >
                    <h1 style={{ fontSize: "25px", paddingTop: "12px" }}>Careers</h1>

                    <div className="filter" id="filter-undergrduate"
                        style={{ backgroundColor: "white", color: "#1A2E5A", transform: "scale(80%)" }}>
                        <h4>Refine your results!</h4>
                        <Row>
                            <Col className="col-12 col-md-9">
                                <SearchCareer
                                    className="form-inline my-2 my-lg-0 ml-auto text-center"
                                    style={{ paddingLeft: "30px", width: "50%", textAlign: "center", marginLeft: "3em" }}
                                    setSearchQuery={this.setSearchQuery}
                                    search={this.state.search}
                                /></Col>
                            <Col className="col-12 col-md-3" style={{ padding: "20px", marginBottom: "1em" }}>
                                <Button className="submit-filter position-absolute bottom-0 end-5 " variant="contained" style={{ marginLeft: "10px", marginRight: "10px" }}
                                    onClick={() => { this.handleSubmit() }}>
                                    Submit
                                </Button>

                                <Button className="submit-filter position-absolute bottom-0 end-0" size="lg" variant="outlined" style={{ marginLeft: "10px", marginRight: "40px" }}
                                    onClick={this.resetForm}>
                                    Reset
                                </Button>
                            </Col>
                        </Row>
                    </div>

                </div>
                <div className="container">

                    <div className="subtitile">
                        <p style={{ fontSize: "18px", paddingTop:"1em" }}>  <small class="text-muted">Your profile says you are </small><span style={{ fontFamily: "Poppins", fontSize: "17px", fontStyle: "bold", color: "1A2E5A", fontWeight: 400 }}>{this.state.riasec_1},  {this.state.riasec_2}, {this.state.riasec_3}</span>! </p>
                        <p style={{ fontSize: "13px", textAlign: "center", marginBottom: 0 }}>These are some recommended career domains you might be interested in. Click view to explore and learn more about the domain with us!</p>
                        {/* <p style={{ textAlign: "left", fontSize: "15px", fontStyle: "italic", margin: 0 }}> Click view to explore and learn more about the domain with us!</p> */}
                    </div>
                    <CareerListings
                        search={this.state.search}
                        careers={this.state.careers}
                        setCareers={this.setCareers}
                        page={this.state.page}
                        setPage={this.setPage}
                        pageCount={this.state.pageCount}
                    />
                </div>

                <AppFooter />
            </Container>

        )
    }

}

export default Careers;