import React, { Component } from 'react';
import { Col, Container, Row, Card, Button } from 'react-bootstrap';
import axios from 'axios';

import './UndergradDetails.css'
import ResponsiveAppBar from '../../components/navbar'
import AppFooter from '../../components/footer';


class CourseCompare extends Component {
    constructor(props) {
        super(props);
        this.state = {
            compareCourse1: null,
            compareCourse2: null,
            courseDetails: [],
        }
    }

    setCompareCourse1 = (data) => {
        this.setState({ compareCourse1: data })
    }

    setCompareCourse2 = (data) => {
        this.setState({ compareCourse2: data })
    }

    setCourseDetail = (data) => {
        this.setState({ courseDetails: data })
    }

    retrieveCourseCompareID = () => {
        let splitString = [];

        if (localStorage.getItem('compareID')) {
            const compareID = localStorage.getItem('compareID')
            console.log("tyoeOf", typeof localStorage.getItem('compareID'))

            splitString = compareID.split(",")
            console.log("compareCourse1: ", splitString[0])
            console.log("compareCourse2: ", splitString[1])

            this.setCompareCourse1(splitString[0])
            this.setCompareCourse2(splitString[1])
        }
        console.log("compare1: ", this.state.compareCourse1)
        console.log("compare2: ", this.state.compareCourse2)

        return `https://api.invictus.com.kh/api/undergraduate-programmes?compare=${splitString[0]},${splitString[1]}`
    }

    componentDidMount = () => {
        this.fetchCourseDetails();
    }

    fetchCourseDetails = () => {
        const url = this.retrieveCourseCompareID()
        console.log(url)

        axios.get(url,
            { withCredentials: true })
            .then(response => {
                console.log("response.data", response.data[0])
                this.setCourseDetail(response.data)
                console.log("courseDetail: ", this.state.courseDetails)
                return response.data
            })
            .catch(error => {
                console.error('Error', error);
            });
    }

    handleClickBack = e => {
        this.props.history.push(`/undergraduate-programmes`);
    };

    render() {
        return (
            <>
                <Container fluid mx="3px" id="courses" style={{ width: "100%", margin: 0, padding: 0 }}>
                    <ResponsiveAppBar />
                    <div className="flex-container" id="course-header">
                        <h1 style={{ fontSize: "25px" }}>Undergraduate Programmes Comparison</h1>
                        <div className="course-subheader" style={{ backgroundColor: "transparent" }}>
                            <h4 style={{ fontSize: "18px", fontFamily: "Poppins Light" }}>Your choice matters</h4>
                        </div>
                    </div>

                    <Container>
                        <Row>
                            <button className="btn" id="back-button" style={{ fontSize: "14px", marginTop: "20px", paddingLeft: 0, marginBottom: "15px", textAlign: "left" }}
                                onClick={this.handleClickBack}>
                                <i class="bi bi-arrow-return-left fa-lg"></i> Back
                            </button>

                            {this.state.courseDetails.map(compare => (
                                <Col className="border-right sm-12 md-6" key={compare.id} style={{ marginLeft: "30px", paddingRight: "20px" }}>
                                    <Card className="compare-title" style={{ paddingTop: "10px", paddingBottom: "10px" }}>
                                        <Card.Body className="font-weight-bold" style={{ textAlign: "center", fontSize: "18px" }}>{compare.name}</Card.Body>
                                    </Card>

                                    <div className="details" id="basicInformation">
                                        <h2 style={{ fontSize: "19px" }}>Basic Information</h2>
                                        <Row className="details-header">
                                            <Col className="col-12 col-md-4 detail-title">
                                                <h5> University</h5>
                                            </Col>
                                            <Col className="col-12 col-md-8 detail">
                                                <p> {compare.university}</p>
                                            </Col>
                                        </Row>

                                        <Row className="details-header">
                                            <Col className="col-12 col-md-4 detail-title">
                                                <h5> Faculty</h5>
                                            </Col>
                                            <Col className="col-12 col-md-8 detail">
                                                <p> {compare.faculty}</p>
                                            </Col>
                                        </Row>

                                        <Row className="details-header">
                                            <Col className="col-12 col-md-4 detail-title">
                                                <h5> Discipline</h5>
                                            </Col>
                                            <Col className="col-12 col-md-8 detail">
                                                <p> {compare.discipline}</p>
                                            </Col>
                                        </Row>

                                        <Row className="details-header">
                                            <Col className="col-12 col-md-4 detail-title">
                                                <h5> Description</h5>
                                            </Col>
                                            <Col className="col-12 col-md-8 detail">
                                                <p>{compare.description}</p>
                                            </Col>
                                        </Row>

                                        <Row className="details-header">
                                            <Col className="col-12 col-md-4 detail-title">
                                                <h5> Programme Type</h5>
                                            </Col>
                                            <Col className="col-12 col-md-8 detail">
                                                <p>{compare.programme_type}</p>
                                            </Col>
                                        </Row>
                                    </div>

                                    <div className="details" id="admission">
                                        <h2 style={{ fontSize: "19px" }}>Admissions</h2>
                                        <Row className="details-header">
                                            <Col className="col-12 col-md-4 detail-title">
                                                <h5> Indicate Grade Profile (IGP)</h5>
                                                <h7> 10th Percentile</h7>
                                            </Col>
                                            <Col className="col-12 col-md-8 detail">
                                                <p>{compare.igp_10th_percentile}</p>
                                            </Col>
                                        </Row>

                                        <Row className="details-header">
                                            <Col className="col-12 col-md-4 detail-title">
                                                <h5>Indicate Grade Profile (IGP)</h5>
                                                <h7>90th Percentile</h7>
                                            </Col>
                                            <Col className="col-12 col-md-8 detail">
                                                <p>{compare.igp_90th_percentile}</p>
                                            </Col>
                                        </Row>

                                        <Row className="details-header">
                                            <Col className="col-12 col-md-4 detail-title">
                                                <h5> Application Period</h5>
                                            </Col>
                                            <Col className="col-12 col-md-8 detail">
                                                <p> 1 February 2021 - 21 February 2021</p>
                                            </Col>
                                        </Row>

                                        <Row className="details-header">
                                            <Col className="col-12 col-md-4 detail-title">
                                                <h5>Tuition Fee with Tuition Grant</h5>
                                                <h7>per Academic Year</h7>
                                            </Col>
                                            <Col className="col-12 col-md-8 detail">
                                                <p>$ {compare.tuition_fee_tg}</p>
                                            </Col>
                                        </Row>
                                    </div>

                                    <div className="row buttons">
                                        <div id="flex-box-compare" className="text-center" style={{ alignContent: "center" }}>
                                            <Button className="moreInformation" size="sm" href={compare.homepage_url} style={{ fontSize: "12px" }}>
                                                Bring Me for More Information
                                            </Button>
                                        </div>
                                    </div>

                                </Col>
                            ))}
                        </Row>
                    </Container>
                    <AppFooter />
                </Container>
            </>
        )
    }
}


export default CourseCompare;