import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import axios from "axios";

import './UndergraduateProgrammes.css';
import ResponsiveAppBar from '../../components/navbar'
import AppFooter from '../../components/footer';
import UniversityFilter from '../../components/filters/selectUniversity';
import DisciplineFilter from '../../components/filters/selectDiscipline';
import SearchProgrammes from '../../components/filters/searchProgrammes';
import ProgrammeListings from '../../components/cards/ProgrammeListings';
import Button from '@mui/material/Button';



class Courses extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedUniversity: [],
            selectedDiscipline: [],
            search: "",
            courses_: [],
            page: 1,
            pageCount: 0,
            total: null,
            loading: false,
            riasec_1: "",
            riasec_2: "",
            riasec_3: "",
        };

        this.setCourses_ = this.setCourses_.bind(this);
    }

    componentDidMount() {
        this.fetchCourses();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.page !== this.state.page) {
            //console.log("page", this.state.page)
            this.fetchCourses();
        }
    }

    setSelectedUniversity = (data) => {
        this.setState({ selectedUniversity: data })
    }

    setSelectedDiscipline = (data) => {
        this.setState({ selectedDiscipline: data })
    }

    setSearchQuery = (data) => {
        // console.log("search: ",data.target.value)
        this.setState({ search: data.target.value })
    }

    setCourses_ = (data) => {
        //console.log("data from setCourses_: ", data)
        this.setState({ courses_: data })
    }

    setRiasec_1 = (data) => {
        this.setState({ riasec_1: data })
    }

    setRiasec_2 = (data) => {
        this.setState({ riasec_2: data })
    }

    setRiasec_3 = (data) => {
        this.setState({ riasec_3: data })
    }

    setPage = (data) => {
        this.setState({ page: data })
    }

    setPageCount = (data) => {
        this.setState({ pageCount: data })
    }

    setTotal = (data) => {
        this.setState({ total: data })
    }

    setLoading = (data) => {
        this.setState({ loading: data })
    }

    getUrlQuery = () => {
        let uniFilter = "&university="
        for (let i = 0; i < this.state.selectedUniversity.length; i++) {
            uniFilter += `${this.state.selectedUniversity[i].value}`;
            if (i < this.state.selectedUniversity.length - 1) {
                uniFilter += ','
            }
        }

        let disciplineFilter = "&discipline="
        for (let i = 0; i < this.state.selectedDiscipline.length; i++) {
            disciplineFilter += `${this.state.selectedDiscipline[i].value}`;
            if (i < this.state.selectedDiscipline.length - 1) {
                disciplineFilter += ','
            }
        }
        return `https://api.invictus.com.kh/api/undergraduate-programmes?search=${this.state.search}&page=${this.state.page}${uniFilter}${disciplineFilter}`;
    }

    resetForm = () => {
        this.setState({
            ...this.state,
            selectedUniversity: [],
            selectedDiscipline: [],
            search: "",
            page: 1,
        }, () => {
            this.fetchCourses();
        })
    }

    handleSubmit = () => {
        this.setState({
            ...this.state,
            page: 1,
        }, () => {
            this.fetchCourses();
        })
    }

    fetchMe = () => {
        axios.get(`https://api.invictus.com.kh/api/me`,
            { withCredentials: true })
            .then(response => {
                this.setRiasec_1(response.data.profile.interest_1.name)
                this.setRiasec_2(response.data.profile.interest_2.name)
                this.setRiasec_3(response.data.profile.interest_3.name)

                return response.data
            })
            .catch(error => {
                console.error('Error', error);
            });
    }

    fetchCourses = () => {
        const URL = this.getUrlQuery();
        axios.get(URL, { withCredentials: true })
            .then(response => {
                this.setCourses_(response.data.results)
                this.fetchMe();
                let contentRange = response.headers["content-range"]
                let total = contentRange.split("/")[1]
                let length = response.data.page_size
                const total_page = Math.ceil(total / length)
                this.setPageCount(total_page)
                this.setTotal(contentRange.split("/")[1])
            })
            .catch(error =>
                console.error('Error', error));
    }


    render() {
        return (
            <Container fluid mx="3px" id="courses" style={{ width: "100%", margin: 0, padding: 0 }}>
                <ResponsiveAppBar />

                <div className="flex-container align-content-sm-stretch" id="course-header">
                    <h1 style={{ fontSize: "25px", paddingTop: "12px" }}>Undergraduate Programmes</h1>

                    <div className="filter" id="filter-undergrduate"
                        style={{ backgroundColor: "white", color: "#1A2E5A" }}>
                        <h4 style={{ fontSize: "16px" }}>Customize your own exploration</h4>
                        <Row>
                            <Col className="col-12 col-md-3">
                                <UniversityFilter
                                    setSelectedUniversity={this.setSelectedUniversity}
                                    selectedUniversity={this.state.selectedUniversity}
                                /></Col>
                            <Col className="col-12 col-md-3">
                                <DisciplineFilter
                                    setSelectedDiscipline={this.setSelectedDiscipline}
                                    selectedDiscipline={this.state.selectedDiscipline}
                                /></Col>
                            <Col className="col-12 col-md-3">
                                <SearchProgrammes
                                    setSearchQuery={this.setSearchQuery}
                                    search={this.state.search}
                                /></Col>
                            <Col className="col-12 col-md-3" style={{ padding: "20px", marginBottom: "1em" }}>
                                <Button className="submit-filter position-absolute bottom-0 end-5 " variant="contained" style={{ marginLeft: "10px", marginRight: "10px", fontSize: "13px" }}
                                    onClick={() => { this.handleSubmit() }}>
                                    Submit
                                </Button>
                                <Button className="submit-filter position-absolute bottom-0 end-0" size="lg" variant="outlined" style={{ marginLeft: "10px", marginRight: "40px", fontSize: "13px" }}
                                    onClick={this.resetForm}>
                                    Reset
                                </Button>
                            </Col>
                        </Row>
                    </div>

                </div>
                <div className="container">
                    <div className="subtitile">
                        <p style={{ fontSize: "18px", paddingTop: "1em" }}>  <small class="text-muted">Your profile says you are </small><span style={{ fontFamily: "Poppins", fontSize: "17px", fontStyle: "bold", color: "1A2E5A", fontWeight: 400 }}>{this.state.riasec_1},  {this.state.riasec_2}, {this.state.riasec_3}</span>! </p>
                        <p style={{ fontSize: "13px", textAlign: "center", marginBottom: 0 }}>These are some recommended undergraduate programmes you might be interested in. Click details to explore and learn more about the education with us!</p>
                    </div>
                    <ProgrammeListings
                        selectedUniversity={this.state.selectedUniversity}
                        selectedDiscipline={this.state.selectedDiscipline}
                        search={this.state.search}
                        courses_={this.state.courses_}
                        setCourses_={this.setCourses_}
                        page={this.state.page}
                        setPage={this.setPage}
                        pageCount={this.state.pageCount}
                    />
                </div>
                <AppFooter />
            </Container>
        )
    }
}

export default Courses;