import styled from 'styled-components';

export const ContainerCard = styled.div`
    margin: 10px;
    perspective: 700;
    
`

export const CardBox = styled.div`
    width: 150px;
    height: 150px;
    transition: all 0.6s ease;
    transform-style: preserve-3d;
    cursor: pointer;

    &:hover{
        transform: rotateY(180deg);
    }
    .back{
        transform: rotateY(180deg);
        background: #F9A826;        
        color: #1A2E5A;
    }
    .front{
        background: #1A2E5A;
        color: white;
    }

    .front, .back{
        position: absolute;
        width: 150px;
        height:150px;
        border-radius: 10px;
        backface-visibility: hidden;
        display:flex;
        align-items:center;
        justify-content:center;
        box-shadow: 4px -1px 15px 0px rgba(153,153,153,0.87);
        -webkit-box-shadow: 4px -1px 15px 0px rgba(153,153,153,0.87);
        -moz-box-shadow: 4px -1px 15px 0px rgba(153,153,153,0.87);
    }
`
