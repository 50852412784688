// import React from 'react';
// import { makeStyles, Theme, createStyles }
//     from '@material-ui/core/styles';
// import Stepper from '@material-ui/core/Stepper';
// import Step from '@material-ui/core/Step';
// import StepLabel from '@material-ui/core/StepLabel';
// import StepContent from '@material-ui/core/StepContent';
// import Button from '@material-ui/core/Button';
// import Paper from '@material-ui/core/Paper';
// import Typography from '@material-ui/core/Typography';


// const useStyles = makeStyles((theme: Theme) =>
//     createStyles({
//         root: {
//             width: '100%',
//         },
//         button: {
//             marginTop: theme.spacing(1),
//             marginRight: theme.spacing(1),
//         },
//         actionsContainer: {
//             marginBottom: theme.spacing(2),
//         },
//         resetContainer: {
//             padding: theme.spacing(3),
//         },
//     }),
// );

// function getSteps() {
//     return [<b style={{ color: 'red' }}>'Pending'</b>,
//     <b style={{ color: 'orange' }}>
//         'Review'</b>, <b style={{ color: 'green' }}>'Published'</b>];
// }

// function getStepContent(step: number) {
//     switch (step) {
//         case 0:
//             return `You submit an Article and it goes to Pending state `;
//         case 1:
//             return 'Article is reviewed';
//         case 2:
//             return `Hey Geek!! Your Article is Published`;
//         default:
//             return 'Unknown step';
//     }
// }

// export default function GeekStepper() {
//     const classes = useStyles();
//     const [activeStep, setActiveStep] = React.useState(0);
//     const steps = getSteps();

//     const handleNext = () => {
//         setActiveStep((prevActiveStep) => prevActiveStep + 1);
//     };

//     const handleBack = () => {
//         setActiveStep((prevActiveStep) => prevActiveStep - 1);
//     };

//     const handleReset = () => {
//         setActiveStep(0);
//     };

//     return (
//         <div className={classes.root}>
//             <h6 className='text-center text-md-right' style={{ alignSelf: 'left' }}>Stage</h6>
//             <Stepper activeStep={activeStep} orientation="vertical">
//                 {steps.map((label, index) => (
//                     <Step key={label}>
//                         <StepLabel>{label}</StepLabel>
//                         <StepContent>
//                             <Typography>{getStepContent(index)}</Typography>
//                             <div className={classes.actionsContainer}>
//                                 <div>
//                                     <Button
//                                         disabled={activeStep === 0}
//                                         onClick={handleBack}
//                                         className={classes.button}
//                                     >
//                                         Back
//                                     </Button>
//                                     <Button
//                                         variant="contained"
//                                         color="primary"
//                                         onClick={handleNext}
//                                         className={classes.button}
//                                     >
//                                         {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
//                                     </Button>
//                                 </div>
//                             </div>
//                         </StepContent>
//                     </Step>
//                 ))}
//             </Stepper>
//             {activeStep === steps.length && (
//                 <Paper square elevation={0}
//                     className={classes.resetContainer}>
//                     <Typography>
//                         All steps completed - your Article is Published
//                     </Typography>
//                     <Button onClick={handleReset} className={classes.button}>
//                         Reset
//                     </Button>
//                 </Paper>
//             )}
//         </div>
//     );
// }

import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { Container, Row, Col, Card } from 'react-bootstrap';


const steps = [
    {
        label: 'Empathy',
        description: `Empathy is crucial to a human-centered design process such as design thinking as it allows design 
        thinkers to set aside their own assumptions about the world and gain real insight into users and their needs.`,
    },
    {
        label: 'Define',
        description: `In the Define stage, you will accumulate the information you have created and gathered during the Empathize stage.
            You’ll analyze your observations and synthesize them to define the core problems you and your team have identified up to this point. 
            You should always seek to define the problem statement in a human-centered manner as you do this.`,
    },
    {
        label: 'Ideate',
        description: `The ideas gathered in Stage 2 will help students define the research question. 
        Students will start generating ideas to address users’ needs, and brainstorm solutions to address the problem. 
        Students will learn and apply various ideation techniques such as ‘Brainstorm’, ‘SCAMPER’, ‘Worst Possible Idea’ 
        to stimulate thinking and to expand the problem space so as to generate as many ideas as possible before 
        finding the best way to solve the problem.
        `,
    },
    {
        label: 'Prototype',
        description: `This is an experimental phase. The aim is to identify the best possible solution for each problem found. 
        Your team should produce some inexpensive, scaled-down versions of the product (or specific features found within the product)
         to investigate the ideas you’ve generated. This could involve simply paper prototyping.
        `,
    },
    {
        label: 'Test',
        description: `Evaluators rigorously test the prototypes. Although this is the final phase, design thinking is iterative: 
        Teams often use the results to redefine one or more further problems. So, you can return to previous stages to make further iterations,
         alterations and refinements – to find or rule out alternative solutions.
        `,
    },
    {
        label: 'Service Learning',
        description: `Service learning is an approach to teaching and learning that helps students develop a sense of social responsibility. 
        Service learning is rooted in the belief that active participation in learning activities deepens understanding. 
        It's also based on the premise that the desire to provide service is driven by a deep, human need to be responsible for others.

        `,
    },
];

export default function DTStepper() {
    const [activeStep, setActiveStep] = React.useState(0);

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    return (
        <Container style={{ marginTop: '2em' }}>
            <h6 className="text-left">Stages</h6>
            <Row>
                <Col md={5}>
                    <Box sx={{ maxWidth: 400 }}>
                        <Stepper nonLinear activeStep={activeStep} orientation="vertical">
                            {steps.map((step, index) => (
                                <Step key={step.label}>
                                    <StepLabel
                                    // optional={
                                    //     index === 2 ? (
                                    //         <Typography variant="caption">Last step</Typography>
                                    //     ) : null
                                    // }
                                    >
                                        {step.label}
                                    </StepLabel>
                                    <StepContent style={{ marginLeft: '2em', paddingLeft: "20px", textAlign: 'left' }}>
                                        <Typography style={{ textAlign: 'left' }}>{step.description}</Typography>
                                        <Box sx={{ mb: 2 }}>
                                            <div>
                                                <Button
                                                    variant="contained"
                                                    onClick={handleNext}
                                                    sx={{ mt: 1, mr: 1 }}
                                                >
                                                    {index === steps.length - 1 ? 'Finish' : 'Continue'}
                                                </Button>
                                                <Button
                                                    disabled={index === 0}
                                                    onClick={handleBack}
                                                    sx={{ mt: 1, mr: 1 }}
                                                >
                                                    Back
                                                </Button>
                                            </div>
                                        </Box>
                                    </StepContent>
                                </Step>
                            ))}
                        </Stepper>
                        {activeStep === steps.length && (
                            <Paper square elevation={0} sx={{ p: 3 }}>
                                <Typography>All steps completed - you&apos;re finished</Typography>
                                <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
                                    Reset
                                </Button>
                            </Paper>
                        )}
                    </Box>
                </Col>

                <Col md={7}>
                    <Card>
                        <Card.Header as="h6" className="text-left" style={{ alignItems: 'left', padding: '14px' }}>Research Log</Card.Header>
                        <Card.Body>
                            {/* <Card.Title>Research Log</Card.Title> */}
                            {/* <Card.Text>
                                With supporting text below as a natural lead-in to additional content.
                            </Card.Text> */}
                            <ul class="list-group list-group-flush">
                                <li class="list-group-item">
                                    <Row>
                                        <Col md={2} className="text-left">
                                            <img src='https://cdn-icons-png.flaticon.com/512/3749/3749784.png' style={{ width: '50%', alignContent: 'left' }}></img>
                                        </Col >
                                        <Col md={7} className="text-left">
                                            Week 1
                                        </Col>
                                        <Col md={3} className="text-right" style={{ color: 'grey' }}>
                                            Viewed
                                        </Col>
                                    </Row>
                                </li>
                                <li class="list-group-item">
                                    <Row>
                                        <Col md={2} className="text-left">
                                            <img src='https://cdn-icons-png.flaticon.com/512/3749/3749784.png' style={{ width: '50%', alignContent: 'left' }}></img>
                                        </Col >
                                        <Col md={7} className="text-left">
                                            Week 2
                                        </Col>
                                        <Col md={3} className="text-right" style={{ color: 'grey' }}>
                                            Submitted
                                        </Col>
                                    </Row>
                                </li>
                            </ul>
                            <Button variant="primary" size='sm' variant="contained" style={{ backgroundColor: '#1A2E5A', fontSize: '12px' }}> + New Submission</Button>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
}

