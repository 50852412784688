import * as React from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import ListSubheader from '@mui/material/ListSubheader';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';

export default function Eventlistdb() {
  return (
    <ImageList sx={{ width: 500, height: 420 }} variant="quilted"
    cols={1}
    rowHeight={121}>

      {itemData.map((item) => (
        <ImageListItem key={item.img}>
          <img
            src={`${item.img}?w=248&fit=crop&auto=format`}
            srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
            alt={item.title}
            loading="lazy"
           
          />
          <ImageListItemBar
            title={item.title}
            subtitle={item.author}
            actionIcon={
              <IconButton
                sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                aria-label={`info about ${item.title}`}
                href={item.action} target="_blank"
              >
                <InfoIcon />
              </IconButton>
            }
          />
        </ImageListItem>
      ))}
    </ImageList>
  );
}

const itemData = [
  {
    img: 'https://custom.cvent.com/F51608C67F874E149EF23800B09DB0AC/files/event/1A7CA020F90548BB9D11F848D4FDBE0A/bf42e8eceaf14aafb6e0b44b82425668.png',
    title: 'Meet The Expert: Curtin University',
    author: '@IDP Singapore (The Cathay/Virtual)',
    rows: 2,
    cols: 2,
    featured: true,
    action: "https://www.idp.com/singapore/idpevents/meet-the-expert-curtin-university/03062022/syngjqt5rjj/",
  },
  {
    img: 'https://custom.cvent.com/F51608C67F874E149EF23800B09DB0AC/files/event/12BDC81475DF4F60B6C7514892200396/ce84c3ceec6f4893ba85f44470f5c2f9.jpg',
    title: 'Meet The Expert: The University of Melbourne',
    author: '@IDP Singapore (The Cathay/Virtual)',
    action:"https://www.idp.com/singapore/idpevents/meet-the-expert-the-university-of-melbourne/24052022/32nmtjg86kx/"
  },
  {
    img: 'https://custom.cvent.com/F51608C67F874E149EF23800B09DB0AC/files/event/5DA64EBB08834205A64A8A4B7252049F/0dad3bed4026473fab75d7dc7ae55185.png',
    title: 'Study Biological and Biomedical Sciences',
    author: '@IDP Singapore @ The Cathay',
    action:"https://www.idp.com/singapore/idpevents/study-biological-and-biomedical-sciences/26052022/4ynxx38w7k7/"
  },
];
