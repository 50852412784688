import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import CardMedia from "@mui/material/Grid";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import profilepic from "../../assets/27-Businesswoman-512.jpg";
import FormControl, { useFormControl } from '@mui/material/FormControl';

export default function ExperiencesCard() {
  return (
    <Card  sx={{ minWidth: 275 }}>
    <Grid container >
         
      <Grid item xs={9}>
         <Card  elevation={0} sx={{ minWidth: 275 }}>
          <CardContent>
          <Box
      component="form"
      sx={{
        '& .MuiTextField-root': { m: 3, width: '50ch', maxWidth: '80%'},
       }}
      noValidate
      autoComplete="off"
    >
            <div>
              <TextField
                id="standard-read-only-input"
                label="Organisation"
                defaultValue="Programming Club"
                InputProps={{
                  readOnly: true,
                }}
                variant="standard"
              />
              <TextField
                id="standard-read-only-input"
                label="Position"
                defaultValue="Club Leader"
                InputProps={{
                  readOnly: true,
                }}
                variant="standard"
              />
               <TextField
                id="standard-read-only-input"
                label="Start Date"
                defaultValue="08 March 2019"
                InputProps={{
                  readOnly: true,
                }}
                variant="standard"
              />
              <TextField
                id="standard-read-only-input"
                label="End Date"
                defaultValue="08 March 2021"
                InputProps={{
                  readOnly: true,
                }}
                variant="standard"
              />
              
            </div>
            </Box>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={3}>
         <Card  elevation={0} sx={{ minWidth: 275 , height:234}}>
          <CardContent>
          <br/>
          Attached Document:
          <br/>
          {"You have not upload any document =)"}
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12}>
      <Card elevation={0} sx={{ minWidth: 275 }}>
          <CardContent>
      <FormControl sx={{ width: '150ch' }}>
              <TextField fullWidth 
                id="standard-read-only-input"
                label="Description"
                multiline
                rows={3}
                defaultValue="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
                InputProps={{
                  readOnly: true,
                }}
                variant="filled"
              />
              </FormControl>
              </CardContent>
        </Card>
      </Grid>
     
      </Grid>
      </Card>
      
  );
}
