import axios from "axios";
import React, { Component } from 'react';
import { Redirect } from "react-router-dom";
import { Container } from '@mui/material';
import { Form, Button, FormControl } from "react-bootstrap";

import ResponsiveAppBar from '../../components/navbar'
import AppFooter from '../../components/footer';
import "../../components/cards/cards.css"
import ChangePasswordPic from '../../assets/undraw_work_in_progress_uhmv.svg';


const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
const BASE_API_URL = `https://api.invictus.com.kh/api`


class ChangePassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            csrf: "",
            oldPassword: "",
            newPassword: "",
            isAuthenticated: false,
            alertMessage: "",
            error: "",
            success: ""
        }

        this.setAlertMessage = this.setAlertMessage.bind(this);
    }

    setOldPassword = (data) => {
        this.setState({ oldPassword: data })
    }

    setNewPassword = (data) => {
        this.setState({ newPassword: data })
    }

    setAlertMessage = (data) => {
        this.setState({ alertMessage: data })
    }

    setIsAuthenticated = (data) => {
        this.setState({ isAuthenticated: data })
    }

    componentDidMount = () => {
        this.getSession();
    }

    getCSRF = () => {
        fetch("https://api.invictus.com.kh/api/csrf/", {
            credentials: "include",
        })
            .then((res) => {
                let csrfToken = res.headers.get("X-CSRFToken");
                this.setState({ csrf: csrfToken });
                console.log("csrfToken: ", csrfToken);
            })
            .catch((err) => {
                console.log(err);
            });
    }

    // check if user is authenticated 
    getSession = () => {
        fetch("https://api.invictus.com.kh/api/session/", {
            credentials: "include",
        })
            .then((res) => res.json())
            .then((data) => {
                console.log(data);
                this.getCSRF();
                if (data.isAuthenticated) {
                    this.setState({ isAuthenticated: true });
                    this.setIsAuthenticated(true)
                    // console.log("isAuthenticated: ", this.state.isAuthenticated)
                    // console.log("after set: ", isAuthenticated)
                } else {
                    this.setState({ isAuthenticated: false });
                    this.setIsAuthenticated(false)
                    // this.getCSRF();
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }

    onChange = e => {
        this.setState({ [e.target.name]: e.target.value });
    };

    submitPasswordChange = (event) => {
        event.preventDefault();

        // reset alert message to empty 
        this.setAlertMessage();

        const data = {
            old_password: this.state.oldPassword,
            new_password: this.state.newPassword
        }

        const config = {
            headers: { "X-CSRFToken": this.state.csrf },
            withCredentials: true,
        }

        axios.post(`${BASE_API_URL}/change-password`, data, config)
            .then(res => {
                console.log("res: ", res.data.success)
                this.setState({ success: res.data.success })
            })
            .catch((err) => {
                console.error("Error: ", err.response.data.errors.old_password[0])
                console.error("Error: ", err.response.data)
                const errMessage_old = err.response.data.errors.old_password[0]
                // const errMessage_new = err.response.data.errors.new_password
                this.setState({ error: errMessage_old })
            })
    }

    render() {
        // if (this.state.isAuthenticated == false) {
        //     console.log("if", !this.state.isAuthenticated)
        //     return <Redirect to='/login/'></Redirect>
        // }
        return (
            <Container mx="3px" id="courses" style={{ width: "100%", margin: 0, padding: 0, maxWidth: "initial" }}>
                <ResponsiveAppBar />

                <div className="flex-container" id="course-header">
                    <h1 style={{ fontSize: "25px" }}>Change Password</h1>
                </div>

                <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-5" style={{ marginTop: "3.5em", maxHeight: "400px" }}>
                            <div className="row" style={{ display: "flex", textAlign: "center" }}>
                                <img src={ChangePasswordPic} className="ChangePasswordLogo" id="ChangePasswordLogo" alt="logo"
                                    style={{ width: "auto", height: "70%" }} />
                            </div>
                        </div>

                        <div className="col-12 col-md-7">
                            <div className="row">
                                <div className="title-changePassword" style={{ marginTop: "2em", marginBottom: "2em", textAlign: "center" }}>
                                    <h3 style={{ fontSize: "18px" }}>Change Password</h3>
                                    <p style={{ fontSize: "15px" }}>Please complete the form below to reset your password </p>
                                </div>

                                {this.state.error && (
                                    <div className="form-group">
                                        <div className="alert alert-danger" role="alert">
                                            {this.state.error}
                                        </div>
                                    </div>
                                )}

                                {this.state.success && (
                                    <div className="form-group">
                                        <div className="alert alert-success" role="alert">
                                            {this.state.success}
                                        </div>
                                    </div>
                                )}

                                <div className="card mx-auto" style={{ maxHeight: "300px", maxWidth: "500px", display: "flex" }}>
                                    <Form onSubmit={this.submitPasswordChange}
                                        style={{ paddingLeft: "3em", paddingTop: "1em", paddingRight: "2em", paddingBottom: "3em", fontSize: "13px" }}>
                                        <Form.Group className="mb-3" controlId="oldPassword">
                                            <Form.Label>Old Password</Form.Label>
                                            <Form.Control type="password" placeholder="Enter Old Password" required
                                                name="oldPassword" style={{ fontSize: "13px" }} value={this.state.oldPassword} onChange={this.onChange} />
                                            <FormControl.Feedback type="invalid">{this.state.error &&
                                                <h3 className="error"> {this.state.error} </h3>}</FormControl.Feedback>
                                        </Form.Group>

                                        <Form.Group className="mb-3" controlId="newPassword">
                                            <Form.Label>New Password</Form.Label>
                                            <Form.Control type="password" placeholder="Enter Your New Password" required
                                                name="newPassword" style={{ fontSize: "13px" }} value={this.state.newPassword} onChange={this.onChange} />
                                            <FormControl.Feedback type="invalid">{this.state.error &&
                                                <h3 className="error"> {this.state.error} </h3>}</FormControl.Feedback>
                                        </Form.Group>
                                        
                                        <div className="row" style={{ justifyContent: "center" }}> 
                                        <Button variant="primary" type="submit" size="sm" style={{ width:"auto" }}>
                                            Submit
                                        </Button>
                                        </div>
                                    </Form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <AppFooter />
            </Container>
        )
    }
}

export default ChangePassword;
