import React from "react";

const SearchCareer = (props) => {
    const { setSearchQuery, search } = props

    return (
        <div>
            <div className="mb-3">
                <label className="form-label">Search</label>
                <input
                    id="searchFilter"
                    type="text"
                    className="form-control"
                    placeholder="Enter Programme Name"
                    value = {search}
                    onChange={setSearchQuery}
                    label="Search Programme"
                    style={{alignContent:"center"}}
                />
            </div>
        </div>
    )
}


export default SearchCareer;