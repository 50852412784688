import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import ResponsiveAppBar from '../../components/navbar';
import LinkTab from '../../components/menu_bar/dt-menu';
import AppFooter from '../../components/footer';
import { Box } from '@mui/material';
import DTCard from '../../components/cards/DTStage';

import './DesignThinking.css';
import DesignThinkingProcess from '../../assets/DesignThinking.PNG';


class DesignThinking extends Component {
    render() {
        return (
            <Container fluid mx="3px" id="courses" style={{ width: "100%", margin: 0, padding: 0 }}>
                <ResponsiveAppBar />

                <div className="flex-container align-content-sm-stretch" id="dt-header">
                    <h1 style={{ fontSize: "25px", paddingTop: "12px", textAlign: "center" }}>Design Thinking</h1>

                    <div className="row dt-subtitle"
                        style={{ fontSize: "19px", fontFamily: "Open Sans", background: "transparent" }}>
                        <p style={{ textAlign: "center", fontSize: "18px" }}>Speacially brought to you by Invictus</p>
                    </div>

                    <LinkTab />

                </div>
                <div className="container">
                    <div className="overview" style={{ margin: "1em" }}>
                        <h1 style={{ textAlign: "left", alignContent: "left", fontSize: "20px" }}>
                            What is Design Thinking?
                        </h1>
                        <p style={{ textAlign: "left" }}>
                            It is a design methodology that provides a solution-based approach to solving real-world problems which can be ill-defined or unknown.
                            The solutions are human-centric and strong in user empathy. In addition, Design Thinking is big on teamwork and collaboration.
                            Creativity thrives when people work together in a team as they brainstorm and bounce off ideas;
                            and the collaborative processes provide ample opportunities for students to engage and interact as they apply, home and strengthen their communication skills.
                        </p>
                        <p>
                            Stanford d.school DT model outlines five main gears --- <b> Empathise, Define, Ideation, Prototype, Test </b>.
                        </p>
                    </div>

                    <div className="dt-pic" style={{ alignSelf: "center", justifyContent: "center", display: "flex" }}>
                        <img className="design-thinking" src={DesignThinkingProcess} ></img>
                    </div>

                    <div className="dt-info" style={{ marginTop: "10px" }}>
                        <DTCard />
                    </div>

                    <div style={{ width: '100%' }}>
                        <Box
                            component="span"
                            sx={{
                                display: 'block',
                                p: 4,
                                m: 5,
                                bgcolor: (theme) => (theme.palette.mode === 'dark' ? '#101010' : '#fff'),
                                color: (theme) =>
                                    theme.palette.mode === 'dark' ? 'grey.300' : 'grey.800',
                                border: '1px solid',
                                borderColor: (theme) =>
                                    theme.palette.mode === 'dark' ? 'grey.800' : 'grey.300',
                                borderRadius: 2,
                                fontSize: '0.875rem',
                                fontWeight: '700',
                                textAlign: 'center'
                            }}
                        >
                            Students will apply Design Thinking to service learning projects where they will ideate, propose solutions to address community needs.
                        </Box>
                    </div>

                    <div className="service-learning" style={{ margin: "1em" }}>
                        <h1 style={{ textAlign: "left", alignContent: "left", fontSize: "20px" }}>
                            What is Service Learning?
                        </h1>
                        <p style={{ textAlign: "left" }}>
                            Service learning is an approach to teaching and learning that helps students develop a sense of social responsibility.
                            Service learning is rooted in the belief that active participation in learning activities deepens understanding.
                            It's also based on the premise that the desire to provide service is driven by a deep, human need to be responsible for others.
                        </p>
                    </div>

                </div>
                <AppFooter />
            </Container>
        )
    }
}

export default DesignThinking;