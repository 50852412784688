import React, { Component } from 'react';
import { Container } from 'react-bootstrap';
import ResponsiveAppBar from '../../components/navbar';
import IPQNavTabs from '../../components/menu_bar/ipq-menu';
import AppFooter from '../../components/footer';

import './IPQ.css';

class IPQ extends Component {
    render() {
        return (
            <Container fluid mx="3px" id="courses" style={{ width: "100%", margin: 0, padding: 0 }}>
                <ResponsiveAppBar />

                <div className="flex-container align-content-sm-stretch" id="dt-header">
                    <h1 style={{ fontSize: "25px", paddingTop: "12px", textAlign: "center" }}>Cambridge IPQ</h1>

                    <div className="row dt-subtitle"
                        style={{ fontSize: "19px", fontFamily: "Open Sans", background: "transparent" }}>
                        <p style={{ textAlign: "center", fontSize: "18px" }}>Speacially brought to you by Invictus</p>
                    </div>

                    <IPQNavTabs />

                </div>
                <div className="container">
                    <div className="overview" style={{ margin: "2em" }}>
                        <h1 style={{ textAlign: "left", alignContent: "left", fontSize: "20px", marginTop: '1em' }}>
                            What is Cambridge IPQ?
                        </h1>

                        <p style={{ textAlign: "left", marginTop: '1em' }}>
                            Cambridge International Project Qualification (Cambridge IPQ) is an exciting project-based qualification.
                            Students have the opportunity to develop skills by carrying out research into a topic of their choice.
                            Taking Cambridge IPQ allows learners to demonstrate engagement with their chosen topic beyond preparation for an exam,
                            helping them to stand out from the crowd with university and job applications.
                        </p>

                        <div className="dt-pic" style={{ alignSelf: "center", justifyContent: "center", display: "flex" }}>
                            <img className="design-thinking" src='https://images.unsplash.com/photo-1576267423445-b2e0074d68a4?ixlib=rb-1.2.1&raw_url=true&q=80&fm=jpg&crop=entropy&cs=tinysrgb&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870'></img>
                        </div>

                        <h1 style={{ textAlign: "left", alignContent: "left", fontSize: "20px", marginTop: '2em' }}>
                            Who is Cambridge IPQ for?
                        </h1>

                        <p style={{ textAlign: "left", marginTop: '1em' }}>
                            Cambridge IPQ is typically for learners aged 16 to 19 years.
                            It is ideal for students who want to extend learning beyond their Cambridge International AS and A Levels and are looking for an opportunity
                            to stand out from the crowd when applying to universities and employers.
                        </p>
                        <p style={{ textAlign: "left" }}>
                            Studying for the Cambridge IPQ allows learners to demonstrate engagement with their chosen topic beyond preparation for an exam.
                            Academic evidence suggests that this sort of deeper engagement will help develop their ability to be life-long learners.
                        </p>

                        <h1 style={{ textAlign: "left", alignContent: "left", fontSize: "20px", marginTop: '2em' }}>
                            What will students learn?
                        </h1>
                        <p style={{ textAlign: "left", marginTop:'1em' }}>
                            This is a skills-based qualification. Learners develop higher-order thinking skills that universities and employers look for,
                            including analysis, evaluation and synthesis. It also builds their research, reflection and communication skills.
                        </p>
                    </div>
                </div>
                <AppFooter />
            </Container>
        )
    }
}

export default IPQ;