import { Container, Row, Col } from 'react-bootstrap';
import { CardBox, ContainerCard } from './DTStageStyle'
import React, { Component } from "react";


class DTCard extends Component {
    render() {
        return (
            <Container style={{ textAlign: 'center' }}>
                <Row>
                    <Col>
                        <ContainerCard>
                            <CardBox>
                                <div className="front">
                                    Stage 1: Empathy
                                </div>
                                <div className="back">
                                    Observe, Engage, Understand User Needs
                                </div>
                            </CardBox>
                        </ContainerCard>
                    </Col>

                    <Col>
                        <ContainerCard>
                            <CardBox>
                                <div className="front">
                                    Stage 2: Define
                                </div>
                                <div className="back">
                                    Analyse, Synthesise, Define problem statement
                                </div>
                            </CardBox>
                        </ContainerCard>
                    </Col>

                    <Col>
                        <ContainerCard>
                            <CardBox>
                                <div className="front">
                                    Stage 3: Ideate
                                </div>
                                <div className="back">
                                    Conceptualise, Innovate, Design solutions
                                </div>
                            </CardBox>
                        </ContainerCard>
                    </Col>

                    <Col>
                        <ContainerCard style={{ padding: 0 }}>
                            <CardBox>
                                <div className="front">
                                    Stage 4: Prototype
                                </div>
                                <div className="back">
                                    Create, Application, Evaluate product
                                </div>
                            </CardBox>
                        </ContainerCard>
                    </Col>

                    <Col>
                        <ContainerCard>
                            <CardBox>
                                <div className="front">
                                    Stage 5: Test
                                </div>
                                <div className="back">
                                    Validate, Review, Refine product to meet user’s needs
                                </div>
                            </CardBox>
                        </ContainerCard>
                    </Col>
                </Row>
            </Container>
        )
    }
}

export default DTCard;