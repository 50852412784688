
import SignInPic from '../../assets/signInPic.PNG';
import './login.css';
import React, { Component } from "react";
import Cookies from "universal-cookie";
import { Redirect } from "react-router-dom";

import { Container, Button, Row, Col, Form, FormControl } from "react-bootstrap";
import axios from 'axios';


class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            csrf: "",
            email: "",
            password: "",
            error: "",
            isAuthenticated: false,
            onBoarded: false

        };
    }

    componentDidMount = () => {
        this.getSession();
    }

    setOnboarding = (data) => {
        this.setState({ onBoarded: data }, () =>
            console.log(this.state.onBoarded));
    }

    //
    getCSRF = () => {
        return fetch("https://api.invictus.com.kh/api/csrf/", {
            credentials: "include",
        })
            .then((res) => {
                let csrfToken = res.headers.get("X-CSRFToken");
                console.log("csrfToken: ", csrfToken);
                return csrfToken
            })
            .catch((err) => {
                console.log(err);
            });
    }

    // check if user is authenticated 
    getSession = () => {
        fetch("https://api.invictus.com.kh/api/session/", {
            credentials: "include",
        })
            .then((res) => res.json())
            .then((data) => {
                console.log(data);
                if (data.isAuthenticated) {
                    this.setState({ isAuthenticated: true });
                    //console.log("HERE: ", this.state.isAuthenticated)
                } else {
                    this.setState({ isAuthenticated: false });
                    this.getCSRF().then((csrfToken) => this.setState({ csrf: csrfToken }));
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }
    //
    whoami = () => {
        fetch("https://api.invictus.com.kh/api/whoami/", {
            headers: {
                "Content-Type": "application/json",
            },
            credentials: "include",
        })
            .then((res) => res.json())
            .then((data) => {
                console.log("You are logged in as: " + data.email);
            })
            .catch((err) => {
                console.log(err);
            });
    }
    //
    handlePasswordChange = (event) => {
        this.setState({ password: event.target.value });
    }
    //
    isResponseOk(response) {
        if (response.status >= 200 && response.status <= 299) {
            return response.json();
        } else {
            throw Error(response.statusText);
        }
    }
    //
    login = (event) => {
        event.preventDefault();
        fetch("https://api.invictus.com.kh/api/login/", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "X-CSRFToken": this.state.csrf,
            },
            credentials: "include",
            body: JSON.stringify({
                email: this.state.email,
                password: this.state.password
            }),
        })
            .then(this.isResponseOk)
            .then((data) => {
                console.log("login data: ", data);
                this.setState({
                    isAuthenticated: true,
                    onBoarded: data.onboarded,
                    // rememberMe: true,
                    email: "",
                    password: "",
                    error: ""
                });
                this.fetchMe()
            })
            .catch((err) => {
                console.log(err);
                this.setState({ error: "Wrong email or password. Please try again." });
            });
    }

    getCookie = (cname) => {
        let name = cname + "=";
        let decodedCookie = decodeURIComponent(document.cookie);
        let ca = decodedCookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }

    //
    logout = () => {
        this.getCSRF().then((csrf) => {
            // console.log(document.cookie)
            // const csrf = this.getCookie('csrftoken')
            console.log("csrf", csrf)
            // console.log("csrf state: ", this.state.csrf)
            fetch("https://api.invictus.com.kh/api/logout/", {
                method: "POST",
                credentials: "include",
                headers: {
                    "X-CSRFToken": csrf,
                },
            })
                .then(this.isResponseOk)
                .then((data) => {
                    console.log(data);
                    window.localStorage.clear();
                    window.location.href = "/login/"
                })
                .catch((err) => {
                    console.log(err);
                });

        });
    };

    onChange = e => {
        this.setState({ [e.target.name]: e.target.value });
    };

    onLoginClick = () => {
        const userData = {
            email: this.state.email,
            password: this.state.password
        };
        console.log("Login" + userData.email + " " + userData.password)
    };

    fetchMe = () => {
        axios.get(`https://api.invictus.com.kh/api/me`,
            { withCredentials: true })
            .then(response => {
                console.log("response.data.profile.interest_1: ", response.data.profile.interest_1)
                // console.log("response null? : ",response.data.profile.interest_1===null)
                console.log("onboarded data: ", response.data.onboarded)
                this.setOnboarding(response.data.onboarded)

                console.log("onboarded(state): ", this.state.onBoarded)
                console.log("response.data: ", response.data)
                return response.data
            })
            .catch(error => {
                console.error('Error', error);
            });
    }


    render() {
        if (this.state.isAuthenticated && this.state.onBoarded) {
            console.log("if state(onboard): ", this.state.onBoarded)
            return <Redirect to='/career-domains'></Redirect>
        } else if (this.state.isAuthenticated && !this.state.onBoarded) {
            console.log("else state(onboard): ", !this.state.onBoarded)
            return <Redirect to='/onboarding'></Redirect>

        }
        return (
            <Container id="loginForm">
                {/* <h3>LOGIN</h3> */}
                <Row style={{ paddingTop: "8em" }}>
                    <Col md="7">
                        <img src={SignInPic} className="signIn-Logo" id="signinLogo" alt="logo" />
                        {/* <img src={img} className="signIn-Logo" id="top" alt="logo" />
                    <img src={img2} className="signIn-Logo" id="right-img" alt="logo" />
                    <img src={img3} className="signIn-Logo" id="left-img" alt="logo" /> */}
                    </Col>
                    <Col md="5">
                        <h1 style={{fontSize:'36px'}}>Welcome to Invictus Global Schoolhouse!</h1>
                        <p>Please sign in to continue</p>

                        {this.state.error && (
                            <div className="form-group">
                                <div className="alert alert-danger" role="alert">
                                    {this.state.error}
                                </div>
                            </div>
                        )}

                        <Form onSubmit={this.login}>
                            <Form.Group className="SignIn" controlId="emailId">
                                <Form.Label>Email Address</Form.Label>
                                <Form.Control type="email" name="email" placeholder="Enter email address" value={this.state.email} onChange={this.onChange} />
                                <FormControl.Feedback type="invalid">{this.state.error &&
                                    <h3 className="error"> {this.state.error} </h3>}</FormControl.Feedback>
                            </Form.Group>

                            <Form.Group className="SignIn" controlId="passwordId">
                                <Form.Label>Password</Form.Label>
                                <Form.Control type="password" name="password" placeholder="Enter password" value={this.state.password} onChange={this.onChange} />
                                <FormControl.Feedback type="invalid">{this.state.error &&
                                    <h3 className="error"> {this.state.error} </h3>}</FormControl.Feedback>
                            </Form.Group>


                            {/* {this.state.error && (
                                <div className="form-group">
                                    <div className="alert alert-danger" role="alert">
                                        {this.state.error}
                                    </div>
                                </div>
                            )} */}

                            {/* <input type="checkbox" value="IsRememberMe" id="rememberMe" /><label for="rememberMe">Remember Me</label> */}
                            <div className="d-grid gap-2" style={{ marginTop: '1em' }}>
                                {/* <Button onClick={this.onLoginClick} type="submit">Sign In</Button> */}
                                <Button type="submit" style={{ backgroundColor: "#1A2E5A", marginTop: "1em" }}>Sign In</Button>
                                {/* <Button>Sign In</Button> */}
                            </div>
                            {/* {errors.apiError &&
                                <div className="alert alert-danger mt-3 mb-0">{errors.apiError?.message}</div>
                            } */}
                        </Form>
                    </Col>
                </Row>
            </Container>
        );
    };
};


export default Login;