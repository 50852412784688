import React, { useEffect, useState } from "react";
import { Form, FormGroup, FormLabel } from "react-bootstrap";
import { MultiSelect } from "react-multi-select-component";
import { universities, GetUniversity } from "./options";



const customValueRenderer_uni = (selected_uni, _options) => {
    return selected_uni.length <= 2
        ? selected_uni.map(({ label }) => `${label}, `)
        : `${selected_uni.length} Choices Selected`;
};


const UniversityFilter = (props) => {
    const { setSelectedUniversity, selectedUniversity } = props
    const [options_uni, setOptions_uni] = useState([])

    useEffect(() => {
        const uni = async () => await GetUniversity()
            .then(() => {
                setOptions_uni(universities)
            })
        uni();
    }, [])
    useEffect(() => {
        console.log("selected university: ",selectedUniversity)
    }, [selectedUniversity])

    return (
        <div>
            <Form size="small">
                <FormGroup>
                    <FormLabel style={{fontSize:"13px"}}> University </FormLabel>
                    <MultiSelect
                        options={options_uni}
                        value={selectedUniversity}
                        onChange={setSelectedUniversity}
                        labelledBy="Select"
                        valueRenderer={customValueRenderer_uni}
                        style={{height:"38px"}}
                    />
                </FormGroup>
            </Form>
        </div>
    );
};

export default UniversityFilter;

