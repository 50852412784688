import axios from "axios";
import React, { Component, useEffect, useState } from 'react';
import { Checkbox, containerClasses, FormControlLabel, TextField, Pagination, Container } from '@mui/material';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import { useTheme } from '@mui/material/styles';
import { Button, CardActions, Card, Box, CardContent, CardMedia, Typography } from '@mui/material';


import './cards.css';
import NotFound from '../../assets/NotFound.svg'


const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
const BASE_API_URL = `https://api.invictus.com.kh/api`



const BookmarkListings = (props) => {
    const { selectedUniversity, selectedDiscipline, search, courses_, setCourses_, careers, setCareers, page, setPage, pageCount } = props
    const [careerBookmark, setCareerBookmark] = useState([])
    const [courseBookmark, setCourseBookmark] = useState([])
    const [csrf, setCSRF] = useState("")
    const [auth, setAuth] = useState(false)

    const theme = useTheme();

    axios.defaults.withCredentials = true

    useEffect(() => {
        getSession();
    }, [])

    const getCSRF = () => {
        fetch("https://api.invictus.com.kh/api/csrf/", {
            credentials: "include",
        })
            .then((res) => {
                let csrfToken = res.headers.get("X-CSRFToken");
                setCSRF(csrfToken);
                //console.log("csrfToken: ", csrfToken);
            })
            .catch((err) => {
                console.log(err);
            });
    }

    // check if user is authenticated 
    const getSession = () => {
        fetch("https://api.invictus.com.kh/api/session/", {
            credentials: "include",
        })
            .then((res) => res.json())
            .then((data) => {
                console.log(data);
                if (data.isAuthenticated) {
                    setAuth({ isAuthenticated: true });
                    getCSRF();
                } else {
                    setAuth({ isAuthenticated: false });
                    window.location = "/login/"
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }

    useEffect(() => {
        fetchCareerBookmark();
        fetchCourseBookmark();
    }, []);

    const fetchCareerBookmark = () => {
        const config = {
            headers: { "X-CSRFToken": csrf },
            withCredentials: true,
        }

        axios.get(`${BASE_API_URL}/career-domains/bookmark`, config)
            .then(response => {
                console.log("response.data: ", response.data.results)
                setCareerBookmark(response.data.results)
                console.log("careerBookmark: ", careerBookmark)
            })
            .catch(err => {
                console.error(err)
            })
    }

    const fetchCourseBookmark = () => {
        const config = {
            headers: { "X-CSRFToken": csrf },
            withCredentials: true,
        }

        axios.get(`${BASE_API_URL}/undergraduate-programmes/bookmark`, config)
            .then(response => {
                console.log("response.data (course): ", response.data.results)
                setCourseBookmark(response.data.results)
                console.log("courseBookmark: ", courseBookmark)
            })
            .catch(err => {
                console.error(err)
            })
    }

    const handleCourseBookmark = (event, course_id) => {
        let bookmarkAction = event.target.checked ? "bookmark" : "unbookmark"
        console.log("event.target.c: ", event.target.checked)
        const data = {
            action: bookmarkAction,
            programme_id: course_id
        }

        const config = {
            headers: { "X-CSRFToken": csrf },
            withCredentials: true,
        }

        return (
            axios.post(`${BASE_API_URL}/undergraduate-programmes/bookmark`, data, config)
                .then(response => {
                    // manually set bookmarked state so that it changes in the frontend
                    // because we're using checked={course.bookmarked}
                    let course_idx = courseBookmark.findIndex(c => c.id === course_id);
                    courseBookmark[course_idx].bookmarked = !courseBookmark[course_idx].bookmarked
                    setCourseBookmark([...courseBookmark])
                    return response.data
                })
                .catch(error =>
                    console.log("Error", error))
        )
    }

    const handleCareerBookmark = (event, career_id) => {
        let bookmarkAction = event.target.checked ? "bookmark" : "unbookmark"
        console.log("event.target.c: ", event.target.checked)
        const data = {
            action: bookmarkAction,
            career_domain_id: career_id
        }

        const config = {
            headers: { "X-CSRFToken": csrf },
            withCredentials: true,
        }

        return (
            axios.post(`${BASE_API_URL}/career-domains/bookmark`, data, config)
                .then(response => {
                    let career_idx = careerBookmark.findIndex(c => c.id === career_id);
                    console.log("career_idx:", career_idx)
                    careerBookmark[career_idx].bookmarked = !careerBookmark[career_idx].bookmarked
                    setCareerBookmark([...careerBookmark])
                    return response.data
                })
                .catch(error =>
                    console.log("Error", error))
        )
    }


    return (
        <>
            <div className="row">
                <div className="col-sm-12 col-md-6">
                    <h3 style={{ fontSize: "17px" }}>Undergraduate Programmes</h3>

                    {courseBookmark.length > 0 && courseBookmark.map((course) => {
                        return (
                            <Card sx={{ display: 'flex', boxShadow: 6, marginTop: "2em" }} key={course.id}>
                                <CardMedia
                                    component="img"
                                    sx={{ width: 151 }}
                                    image={BASE_API_URL + course.picture}
                                    alt="course_pic"
                                />
                                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                    <CardContent sx={{ flex: '1 0 auto' }}>
                                        <Typography component="div" variant="h5" style={{ fontSize: "15px", fontFamily: "Open Sans" }}>
                                            {course.name}
                                        </Typography>
                                    </CardContent>

                                </Box>
                                <CardActions>
                                    <Button size="small" color="primary" href={`/undergraduate-programmes/${course.id}`}>
                                        VIEW
                                    </Button>
                                </CardActions>

                                <Checkbox
                                    {...label}
                                    icon={<BookmarkBorderIcon />}
                                    checkedIcon={<BookmarkIcon />}
                                    value={course.id}
                                    checked={course.bookmarked}
                                    onChange={(event) => handleCourseBookmark(event, course.id)}
                                />
                            </Card>
                        )
                    })}

                    {courseBookmark.length < 1 ?
                        <div id="wrapper" style={{ paddingTop: "0.5em" }}>
                            <div className="row" style={{ display: "flex", justifyContent: "center" }}>
                                <img className="page404" src={NotFound} style={{ width: "35%" }} />
                            </div>
                            <div id="info" style={{ marginTop: "1em" }}>
                                <h3 style={{ fontFamily: "Open Sans", textAlign: "center", fontSize: "medium" }}>
                                    Opps! You have not bookmarked any programme yet 😔
                                </h3>
                            </div>
                        </div > : null
                    }

                </div>

                <div className="col-sm-12 col-md-6">
                    <div>
                        <h3 style={{ fontSize: "17px" }}>Career</h3>
                    </div>

                    {careerBookmark.length > 0 && careerBookmark.map((career) => {
                        return (
                            <Card sx={{ display: 'flex', boxShadow: 6, marginTop: "2em" }} key={career.id}>
                                <CardMedia
                                    component="img"
                                    sx={{ width: 100 }}
                                    image={BASE_API_URL + career.image}
                                    alt="course_pic"
                                />
                                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                    <CardContent sx={{ flex: '1 0 auto' }}>
                                        <Typography component="div" variant="h5" style={{ fontSize: "15px", width: "auto" }}>
                                            {career.display_name}
                                        </Typography>
                                    </CardContent>
                                </Box>
                                <CardActions>
                                    <Button size="small" color="primary" href={`/career-domains/${career.id}`}>
                                        VIEW
                                    </Button>
                                </CardActions>

                                <Checkbox
                                    {...label}
                                    icon={<BookmarkBorderIcon />}
                                    checkedIcon={<BookmarkIcon />}
                                    value={career.id}
                                    checked={career.bookmarked}
                                    onChange={(event) => handleCareerBookmark(event, career.id)}
                                />
                            </Card>
                        )
                    })}

                    {careerBookmark.length < 1 ?
                        <div id="wrapper" style={{ paddingTop: "0.5em" }}>
                            <div className="row" style={{ display: "flex", justifyContent: "center" }}>
                                <img className="page404" src={NotFound} style={{ width: "35%" }} />
                            </div>
                            <div id="info" style={{ marginTop: "1em" }}>
                                <h3 style={{ fontFamily: "Open Sans", textAlign: "center", fontSize: "medium" }}>
                                    Opps! You have not bookmarked any careers yet 😔
                                </h3>
                            </div>
                        </div > : null
                    }

                </div>
            </div>
        </>
    )
}

export default BookmarkListings;
