// https://www.npmjs.com/package/react-multi-select-component

import React, { useState, useEffect } from "react";
import { Form, FormGroup, FormLabel } from 'react-bootstrap';
import { MultiSelect } from "react-multi-select-component";
import { disciplines, GetDisciplines } from "./options";


const customValueRenderer = (selected_discipline, _options) => {
    return selected_discipline.length <= 2
        ? selected_discipline.map(({ label }) => `${label}, `)
        : `${selected_discipline.length} Choices Selected`;
};

const DisciplineFilter = (props) => {
    const { setSelectedDiscipline, selectedDiscipline } = props
    const [options, setOptions] = useState([])

    useEffect(() => {
        const disci = async () => await GetDisciplines()
            .then(() => {
                setOptions(disciplines)
            })
        disci()
        // setOptions(disci);
        // console.log("options: ",options)
    }, [])
    useEffect(() => {
        //console.log(selectedDiscipline)
    }, [selectedDiscipline])


    return (
        <div>
            <Form>
                <FormGroup>
                    <FormLabel style={{ fontSize: "13px" }}> Disciplines </FormLabel>
                    <MultiSelect
                        options={options}
                        value={selectedDiscipline}
                        onChange={setSelectedDiscipline}
                        labelledBy="Select"
                        valueRenderer={customValueRenderer}
                        style={{ height: "38px", fontSize: "13px" }}
                    />
                </FormGroup>
            </Form>
        </div>
    );
};

export default DisciplineFilter;