import { Container } from 'react-bootstrap';
import React, { useState, useEffect, Component } from 'react';

import './Events.css';
import ResponsiveAppBar from '../../components/navbar'
import AppFooter from '../../components/footer';
import GetAllEvents from './FetchEvent'
import axios from "axios";

axios.defaults.baseURL = "https://api.invictus.com.kh/api";



class Events extends Component {
    render() {
        return (
            <Container fluid mx="3px" id="events" style={{ width: "100%", margin: 0, padding: 0 }}>
                <ResponsiveAppBar />
                <div className='PageTitleContainer'>
                    <div className=" flex-container mx-auto " id="course-header">
                        <div className="PageTitle" id="eventtitle" style={{ fontSize: "25px", fontFamily: "Poppins" }}>Event List</div>
                        <div className="row event-list-title"
                            style={{ fontStyle: "italic", fontSize: "19px", fontFamily: "Poppins", background: "transparent" }}>
                            <p style={{ textAlign: "center", fontSize: "18px" }}>Explore events for your personal development!</p>
                        </div>
                    </div>
                </div>
                <GetAllEvents />
                <AppFooter />
            </Container >
        )
    }

}

export default Events;