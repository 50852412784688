import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea, Container } from '@mui/material';
import { Link } from 'react-router-dom';
import { Row } from 'react-bootstrap';

export default function CuratedProjectsCard() {
    return (
        <Container style={{ margin: '1em', alignContent: 'center', justifyContent: 'center' }}>
            <Row xs={{ cols: 1 }} md={{ cols: 3 }} className="g-4 col d-flex justify-content-center">
                <Card sx={{ maxWidth: 250, marginLeft: '2em', padding: 0 }}>
                    <CardActionArea href='https://projects.fablabs.io/@mauriziovrenna/algae-grower' target="_blank">
                        <CardMedia
                            component="img"
                            height="140"
                            image="https://res.cloudinary.com/dbshcjrt3/image/upload/w_750,h_500,c_fill,g_auto,q_auto/wikifactory-prod-uploads/66802/algae_grower_dark.jpg"
                            alt="micro algae"
                        />
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="div" style={{ fontSize: '16px' }}>
                                @mauriziovrenna/algae-grower
                            </Typography>
                            <Typography variant="body2" color="text.secondary" align="left">
                                A device to harvest fresh Spirulina at home
                            </Typography>
                        </CardContent>
                    </CardActionArea>
                </Card>

                <Card sx={{ maxWidth: 250, marginLeft: '2em', padding: 0 }} >
                    <CardActionArea href='https://projects.fablabs.io/@ritacaraujo/banco-vet' target="_blank">
                        <CardMedia
                            component="img"
                            height="140"
                            width="250"
                            image="https://res.cloudinary.com/dbshcjrt3/image/upload/w_750,h_500,c_fill,g_auto,q_auto/wikifactory-prod-uploads/56634/rita2.png"
                            alt="micro algae"
                        />
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="div" style={{ fontSize: '16px' }}>
                                @ritacaraujo/banco-vet
                            </Typography>
                            <Typography variant="body2" color="text.secondary" align="left">
                                Projeto banco de Benfica, Eselx
                            </Typography>
                        </CardContent>
                    </CardActionArea>
                </Card>

                <Card sx={{ maxWidth: 250, marginLeft: '2em', padding: 0 }} >
                    <CardActionArea href='https://projects.fablabs.io/@marianac/abrigo-compactado' target="_blank">
                        <CardMedia
                            component="img"
                            height="140"
                            width="250"
                            image="https://res.cloudinary.com/dbshcjrt3/image/upload/w_750,h_500,c_fill,g_auto,q_auto/wikifactory-prod-uploads/54042/render5.JPG"
                            alt="micro algae"
                        />
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="div" style={{ fontSize: '16px' }}>
                                @marianac/abrigo-compactado
                            </Typography>
                            <Typography variant="body2" color="text.secondary" align="left">
                                Abrigo temporário que serve de apoio em caso de catástrofes naturais.
                            </Typography>
                        </CardContent>
                    </CardActionArea>
                </Card>

            </Row>
        </Container>

    );
}