import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import ResponsiveAppBar from "../../components/navbar";
import { Container, Row, Col } from "react-bootstrap";
import DT_header from "./DesignThinking/DT_Header";
import DTStepper from "./DesignThinking/DesignThinking";
import Bookmark_header from './Bookmarks/Bookmark_Header';
import Bookmarks from "./Bookmarks/Bookmarks";
import dashboardicon from "../../assets/dashboardicon.png";
import profileicon from "../../assets/profileicon.png";
import brainicon from "../../assets/brainicon.png";
import bookmarkicon from "../../assets/bookmarkicon.png";
import CustomizedAccordions from "../StudentProfile/Portfolio"
import Grid from '@mui/material/Grid';
import Timetabletab from "./Timetabletab"
import GoalCard from "../StudentProfile/GoalCard"
import NewCarousel from "../StudentProfile/NewCarousel"
import Eventlistdb from "../StudentProfile/EventListDB"
import "../StudentProfile/profile.css"
// import PhoneMissedIcon from '@mui/icons-material/PhoneMissed';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    height: 750,
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
}));

const Profilev2 = () => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Container
      fluid
      mx="3px"
      id="courses"
      style={{ width: "100%", margin: 0, padding: 0 }}
    >
      <ResponsiveAppBar />
      <Box className="sideBar_user"
        fluid
        sx={{
          mt: 2,
          bgcolor: "rgb(26, 46, 90)",
          width: 230,
          height: 250,
          color: "white",
        }}
      >
        <Typography variant="h6" style={{ paddingTop: 140, paddingLeft: 20, fontSize: "14px" }}>
          Welcome,
        </Typography>
        <Typography variant="h5" style={{ paddingLeft: 20, fontStyle: "Sans Serif" }}>
          Joseph Wong !
        </Typography>
      </Box>
      <div className={classes.root} >
        <Grid item xs={2} className="sideBar">
          <Tabs
            orientation="vertical"
            value={value}
            onChange={handleChange}
            aria-label="Vertical tabs example"
            className={classes.tabs} style={{ width: 230 }}

          >
            {/* img src={Journeyicon} */}

            <Tab icon={<img src={dashboardicon} height="28" />} iconPosition="start" label="My Dashboard" {...a11yProps(0)} sx={{ justifyContent: 'flex-start', ml: 2 }} />
            <Tab icon={<img src={profileicon} height="28" />} iconPosition="start" label="My Portfolio" {...a11yProps(1)} sx={{ justifyContent: 'flex-start', ml: 2 }} />
            <Tab icon={<img src={brainicon} height="28" />} iconPosition="start" label="Design Thinking" {...a11yProps(2)} sx={{ justifyContent: 'flex-start', ml: 2 }} />
            <Tab icon={<img src={bookmarkicon} height="28" />} iconPosition="start" label="My Bookmarks" {...a11yProps(3)} sx={{ justifyContent: 'flex-start', ml: 2 }} />
          </Tabs>
        </Grid>
        {/* Non responsive dashbroad */}
        <Container fluid>
          <TabPanel value={value} index={0} style={{ marginTop: -260 }} className="tab">
            <div className='PageTitle' style={{ fontSize: 20, width: 3, marginLeft: 95, marginTop:'2em' }}>
              <div className='PageName' style={{ fontSize: 20, width: 200 }}>
                My Dashboard
              </div>
              <div className='PageDescription' style={{ fontSize: 15, width: 225, marginBottom: 20 }}>
                Keep yourself on track
              </div>
            </div>
            <Grid container spacing={2} justifyContent="center">
              <Grid item xs={6}>
                <Timetabletab />
              </Grid>
              <Grid item xs={4}>
                <GoalCard />
              </Grid>
              <Grid item xs={5}>
                <NewCarousel />
              </Grid>
              <Grid item xs={5}>
                <Eventlistdb />
              </Grid>
            </Grid>
          </TabPanel>

          <TabPanel value={value} index={1} style={{ marginTop: -260 }} className="portfolio_header">
            <div className='PageTitle' style={{ fontSize: 20, width: 3, marginLeft: 5, marginTop:'2em' }}>
              <div className='PageName' style={{ fontSize: 20, width: 200 }}>
                My Porfolio
              </div>
              <div className='PageDescription' style={{ fontSize: 15, width: 370, marginBottom: 20 }}>
                Keep yourself updated and future ready!
              </div>
            </div>
            <CustomizedAccordions />
          </TabPanel>
          <TabPanel value={value} index={2} style={{ marginTop: -260 }}>
            <DT_header />
            <DTStepper />
          </TabPanel>
          <TabPanel value={value} index={3} style={{ marginTop: -260 }}>
            <Bookmark_header />
            <Bookmarks />
          </TabPanel>
        </Container >

      </div >
    </Container >
  );
};

export default Profilev2;
