import { Box } from '@mui/material';
import * as React from 'react';
import { Col, Row, Card } from 'react-bootstrap';

export default function DT_header() {
    return (
        <Row className='dt_header justify-content-center' style={{marginTop:'3em'}}>
            <Col sm={4}>
                <img src='https://global-uploads.webflow.com/5e3ce2ec7f6e53c045fe7cfa/603dd7f41add6c13ad2edb31_Frame-161.png' style={{ height: '180px', width: '180px' }}></img>
            </Col>

            <Col sm={8}>
                <h1 style={{ textAlign: 'center', fontSize: '22px', paddingTop: '1em' }}>Design Thinking</h1>
                {/* <Box sz={{ borderColor: 'primary.main'}} >Text</Box> */}
                <Card body style={{ borderRadius: '30px', marginTop: '1em', backgroundColor:'#FFC363', fontSize:'13px', fontFamily:'Poppins' }}>
                    Problem solving, critical thinking, creativity, leadership, collaboration and communication are in high demand, 
                    and one way to reinforce all of these skills along with your hard skills is through Design Thinking.
                </Card>
            </Col>
        </Row>
    )
}