import * as React from 'react';
import axios from "axios";
import { Link } from 'react-router-dom'
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Fade from '@mui/material/Fade';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import { makeStyles } from '@mui/styles';

import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import DraftsIcon from '@mui/icons-material/Drafts';
import SendIcon from '@mui/icons-material/Send';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import StarBorder from '@mui/icons-material/StarBorder';

import RSCAREAlogo from "../assets/RSCAREA logo.png"

import Login from '../containers/Authentication/login';
import '../navbar.css'
const auth = new Login()

const ResponsiveAppBar = (props) => {
    const { isAuthenticated, onBoarded, csrf } = props;
    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [anchorElUser, setAnchorElUser] = React.useState(null);
    const [profilePic, setProfilePic] = React.useState("")

    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };


    React.useEffect(() => {
        fetchMe();
    }, [])

    const fetchMe = () => {
        axios.get(`https://api.invictus.com.kh/api/me`,
            { withCredentials: true })
            .then(response => {
                console.log(response.data)
                console.log(response.data.profile.profile_picture)
                setProfilePic(response.data.profile.profile_picture)
                console.log(profilePic)
                return response.data
            })
            .catch(error => {
                console.error('Error', error);
            });
    }

    const useStyles = makeStyles((theme) => ({
        navlinks: {
            display: "flex",
        },
        customHeight: {
            minHeight: '10000000em'
        },
        logo: {
            // minHeight: '90px',
            minWidth: 30,
            maxHeight: 50,
            // maxWidth: "10vh"
        },
        button: {
            display: "flex",
            justifyContent: "flex-end"
        }

    }));

    const classes = useStyles();


    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    return (
        <AppBar style={{ background: 'white' }}>
            <Container maxWidth="md">
                <Toolbar disableGutters className={classes.customHeight}>
                    <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none',  maxWidth: 'lg' }, pt: 0 }}>
                        <IconButton
                            size="medium"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="default"
                        >
                            <MenuIcon />
                        </IconButton>

                        <Box sx={{ flexGrow: 1 }}>
                            {/* <img src={RSCAREAlogo} alt="RSCAREA" style={{ minWidth: "8.5em", maxWidth: "9.5em", marginBottom: "14px", paddingTop: "10px" }} /> */}
                            <img src="https://igsloto.ca7dev.url3.net/skin/custom/igsloto.ca7dev.url3.net/ca7/entry/ivtg_logo.png" alt="RSCAREA" style={{ minWidth: "8.5em", maxWidth: "9.5em", marginBottom: "14px", paddingTop: "10px" }} />
                            {/* <img src={RSCAREAlogo} alt="RSCAREA" style={{ width: "100%", height: "auto" }} /> */}
                        </Box>
                        <Box sx={{ flexGrow: 1 }} />
                        <Menu
                            id="menu-appbar"
                            className={classes.navlinks}
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: { xs: 'block', md: 'none' },
                            }}
                        >
                            {/* {pages.map((page) => (
                                <MenuItem key={page} onClick={handleCloseNavMenu} className={classes.button}>
                                    <Typography textAlign="center">{page}</Typography>
                                </MenuItem>
                            ))} */}
                            <MenuItem onClick={handleCloseNavMenu} className={classes.button} component={Link} to="/undergraduate-programmes">
                                <Typography textAlign="center">Undergraduate Programmes</Typography>
                            </MenuItem>
                            <MenuItem onClick={handleCloseNavMenu} className={classes.button} component={Link} to="/career-domains">
                                <Typography textAlign="center">Careers</Typography>
                            </MenuItem>
                            <MenuItem onClick={handleCloseNavMenu} className={classes.button} component={Link} to="/events">
                                <Typography textAlign="center">Events</Typography>
                            </MenuItem>
                            {/* <MenuItem onClick={handleCloseNavMenu} className={classes.button} component={Link} to="/events">
                                <Typography textAlign="center">Resources</Typography>
                            </MenuItem> */}
                            <List
                                sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
                                component="nav"
                                aria-labelledby="nested-list-subheader"
                            // subheader={
                            //     <ListSubheader component="div" id="nested-list-subheader">
                            //         Nested List Items
                            //     </ListSubheader>
                            // }
                            >

                                <ListItemButton onClick={handleClick}>
                                    <ListItemText primary="Resources" />
                                    {open ? <ExpandLess /> : <ExpandMore />}
                                </ListItemButton>
                                <Collapse in={open} timeout="auto" unmountOnExit>
                                    {/* <List component="div" disablePadding> */}
                                    {/* <ListItemButton sx={{ pl: 4 }}>

                                            <ListItemText primary="Starred" />
                                            <ListItemText primary="Starred" />
                                            <ListItemText primary="Starred" />
                                            <ListItemText primary="Starred" />
                                        </ListItemButton> */}
                                    {/* </List> */}
                                </Collapse>
                            </List>
                        </Menu>
                    </Box>
                    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex',  minWidth: 'lg'}, width: '100%', justifyContent: "flex-end", m: 1 }}>

                        {/* {pages.map((page) => (
                            <Button
                            key={page}
                            onClick={handleCloseNavMenu}
                            sx={{ my: 2, color: 'black', display: 'block' }}
                            >
                            {page}
                            </Button>
                        ))} */}

                        {/* <img src={RSCAREAlogo} alt="RSCAREA" className={classes.logo} /> */}
                        <img src="https://igsloto.ca7dev.url3.net/skin/custom/igsloto.ca7dev.url3.net/ca7/entry/ivtg_logo.png" alt="RSCAREA" className={classes.logo} />

                        <Box sx={{ flexGrow: 1 }} />

                        <Button onClick={handleCloseNavMenu} component={Link} to="/undergraduate-programmes"
                            sx={{ my: 1, color: 'black', display: 'block', fontSize: "10px" }}>
                            Undergraduate Programmes
                        </Button>
                        <Button onClick={handleCloseNavMenu} component={Link} to="/career-domains" sx={{ my: 1, color: 'black', display: 'block', fontSize: "10px" }}>
                            Careers
                        </Button>
                        <Button onClick={handleCloseNavMenu} component={Link} to="/events" sx={{ my: 1, color: 'black', display: 'block', fontSize: "10px" }}>
                            Events
                        </Button>
                        <div>
                            <Button
                                id="fade-button"
                                aria-controls={open ? 'fade-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? 'true' : undefined}
                                onClick={handleClick}
                                sx={{ my: 1, color: 'black', display: 'block', fontSize: "10px" }}
                            >
                                Resources
                            </Button>
                            <Menu
                                id="fade-menu"
                                sx={{ mt: '25px' }}
                                MenuListProps={{
                                    'aria-labelledby': 'fade-button',
                                }}
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleClose}
                                TransitionComponent={Fade}
                            >
                                <MenuItem onClick={handleClose} component={Link} to="/design-thinking">Design Thinking</MenuItem>
                                <MenuItem onClick={handleClose} component={Link} to="/ipq">IPQ</MenuItem>
                                <MenuItem onClick={handleClose} component={Link} to="/fablab">Fablab</MenuItem>
                                {/* <MenuItem onClick={handleClose}>Others</MenuItem> */}
                            </Menu>
                        </div>

                    </Box>

                    <Box sx={{ flexGrow: 0 }}>
                        <Tooltip title="Open settings">
                            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                                {profilePic.length < 0 ?
                                    <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" /> : <Avatar alt="profilepic" src={profilePic} />}
                            </IconButton>
                        </Tooltip>
                        <Menu
                            sx={{ mt: '25px' }}
                            id="menu-appbar"
                            anchorEl={anchorElUser}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(anchorElUser)}
                            onClose={handleCloseUserMenu}
                        >
                            {/* {settings.map((setting) => (
                                <MenuItem key={setting} onClick={handleCloseNavMenu}>
                                    <Typography textAlign="center">{setting}</Typography>
                                </MenuItem>
                            ))} */}

                            <MenuItem onClick={handleCloseNavMenu} component={Link} to="/profile">
                                <Typography textAlign="center" fontSize="10px">My Profile</Typography>
                            </MenuItem>
                            <MenuItem onClick={handleCloseNavMenu} component={Link} to="/change-password">
                                <Typography textAlign="center" fontSize="10px">Change Password</Typography>
                            </MenuItem>
                            <MenuItem onClick={handleCloseNavMenu} component={Link} to="/bookmark">
                                <Typography textAlign="center" fontSize="10px">Bookmark</Typography>
                            </MenuItem>
                            <MenuItem onClick={() => auth.logout()} >
                                <Typography textAlign="center" fontSize="10px">Log Out</Typography>
                            </MenuItem>
                        </Menu>
                    </Box>
                    {/* </AppBar> */}
                </Toolbar>
            </Container>
        </AppBar >
    );
};

export default ResponsiveAppBar;
