import React from "react";
import Carousel from "react-material-ui-carousel";
import { Paper, Button } from "@mui/material";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";

import Typography from "@mui/material/Typography";
import { Box ,CardActionArea} from "@mui/material";

export default function NewCarousel(props) {
  var items = [
    {
      image:
        "https://onecms-res.cloudinary.com/image/upload/s--7ha5HMek--/c_fill%2Cg_auto%2Ch_468%2Cw_830/fl_relative%2Cg_south_east%2Cl_one-cms:core:watermark:reuters%2Cw_0.1/f_auto%2Cq_auto/v1/one-cms/core/2022-05-21t160812z_1_lynxnpei4k083_rtroptp_3_health-monkeypox.jpg?itok=bpnXWOFu",
      description: "WHO expects more cases of monkeypox to emerge globally",
      action:"https://www.channelnewsasia.com/world/who-expects-more-cases-monkeypox-emerge-globally-2698286"
    },
    {
      image:
        "https://onecms-res.cloudinary.com/image/upload/s--xvWL-nlt--/c_fill%2Cg_auto%2Ch_468%2Cw_830/fl_relative%2Cg_south_east%2Cl_one-cms:core:watermark:reuters%2Cw_0.1/f_auto%2Cq_auto/v1/one-cms/core/2022-05-11t174142z_1_lynxnpei4a0yn_rtroptp_3_cyber-attack.jpg?itok=YRO8o-wn",
      description:
        "More than S$70 million lost this year to email scammers impersonating business partners, employees",
      action:"https://www.channelnewsasia.com/singapore/more-s70-million-lost-year-email-scammers-impersonating-business-partners-employees-2697671"
    },
  ];

  return (
    <Carousel>
      {items.map((item, i) => (
        <Item key={i} item={item} />
      ))}
    </Carousel>
  );
}

function Item(props) {
  return (
    <Card sx={{ maxWidth: 800 }}>
      <CardActionArea href={props.item.action}  target="_blank">
        <Box sx={{ position: "relative" }}>
          <CardMedia component="img" height="400" image={props.item.image} />
          <Box
            sx={{
              position: "absolute",
              bottom: 0,
              left: 0,
              width: "100%",
              bgcolor: "rgba(0, 0, 0, 0.54)",
              color: "white",
              padding: "10px",
            }}
          >
            <Typography variant="h5">{props.item.description}</Typography>
          </Box>
        </Box>
        {/* <Paper>
          
            <img src={props.item.image} height="300"/>
            <p>{props.item.description}</p>

            <Button className="CheckButton">
                Check it out!
            </Button>
        </Paper> */}
      </CardActionArea>
    </Card>
  );
}
