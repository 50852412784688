import React, { Component } from 'react';
import { Container, Button } from 'react-bootstrap';
import ResponsiveAppBar from '../../components/navbar';
import LinkTab from '../../components/menu_bar/dt-menu';
import AppFooter from '../../components/footer';

import './DesignThinking.css';
import LessonMaterial1 from '../../assets/DTLessonMaterial1.jpg';
import LessonMaterial2 from '../../assets/DTLessonMaterial2.jpg';


class DTLessonMaterials extends Component {
    render() {
        return (
            <Container fluid mx="3px" id="courses" style={{ width: "100%", margin: 0, padding: 0 }}>
                <ResponsiveAppBar />

                <div className="flex-container align-content-sm-stretch" id="dt-header">
                    <h1 style={{ fontSize: "25px", paddingTop: "12px", textAlign: "center" }}>Design Thinking</h1>

                    <div className="row dt-subtitle"
                        style={{ fontSize: "19px", fontFamily: "Open Sans", background: "transparent" }}>
                        <p style={{ textAlign: "center", fontSize: "18px" }}>Speacially brought to you by Invictus</p>
                    </div>

                    <LinkTab />
                </div>

                <div className="container justify-content-center">
                    <div className="card  mx-auto  mt-4" style={{ maxWidth: "700px", maxHeight: '180px', marginTop: '2em' }}>
                        <div className="row no-gutters">
                            <div className="col-md-4" style={{ objectFit: "fill", padding: 0 }}>
                                <img src={LessonMaterial1} class="img-fluid card-img" alt="lesson-materials" style={{ height: '180px' }}></img>
                            </div>
                            <div className="col-md-8" style={{ padding: 0 }}>

                                <div className="card-body">
                                    <h5 className="card-title" style={{ fontSize: "16px" }}>Design Thinking Lesson 1</h5>
                                    <p className="card-text" style={{ fontSize: "11px" }}> This lesson includes Introduction to Design Thinking. It teaches you how to identify and empathize users' needs.</p>
                                    <div className="col text-right">
                                        <Button href='#' className="btn btn-color-details" style={{ fontSize: "10px" }}>Download</Button>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div className="card  mx-auto  mt-4" style={{ maxWidth: "700px", maxHeight: '180px', marginTop: '2em' }}>
                        <div className="row no-gutters">
                            <div className="col-md-4" style={{ objectFit: "fill", padding: 0 }}>
                                <img src={LessonMaterial2} class="img-fluid card-img" alt="lesson-materials" style={{ height: '180px' }}></img>
                            </div>

                            <div className="col-md-8" style={{ padding: 0 }}>
                                <div className="card-body">
                                    <h5 className="card-title" style={{ fontSize: "16px" }}>Week 1 Research Skills</h5>
                                    <p className="card-text" style={{ fontSize: "11px" }}>
                                        This lesson includes background research, library skills.
                                        It also allows you to review literate, learn more about methodology, interview and observations.
                                    </p>
                                    <div className="col text-right">
                                        <Button href='#' className="btn btn-color-details" style={{ fontSize: "10px" }}>Download</Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <AppFooter />
            </Container>
        )
    }
}

export default DTLessonMaterials;