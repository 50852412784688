import React, { Component, useContext, createContext, useState } from "react";
import { BrowserRouter as Router, Route, Switch, Redirect, Link } from "react-router-dom";

import Home from "./containers/Home/Home";
import Login from './containers/Authentication/login';
import Onboarding from "./containers/onBoarding/onBoarding";
import Courses from './containers/UndergraduateProgrammes/UndergraduateProgrammes';
import CourseDetails from "./containers/UndergraduateProgrammes/UndergradDetails";
import CourseCompare from "./containers/UndergraduateProgrammes/UndergradCompare";
import Careers from "./containers/Careers/Careers";
import Events from "./containers/Events/Events";
import DesignThinking from "./containers/DesignThinking/DesignThinking";
import DTProjectOutline from "./containers/DesignThinking/ProjectOutline";
import DTLessonMaterials from "./containers/DesignThinking/LessonMaterials";
import Fablab from "./containers/Fablab/Fablab";
import FabCuratedProjects from "./containers/Fablab/CuratedProjects";
import FabLessonMaterials from "./containers/Fablab/LessonMaterials";
import IPQ from "./containers/IPQ/IPQ";
import CareerDetails from "./containers/Careers/CareerDetails";
import CareerCompare from "./containers/Careers/CareerCompare";
import Bookmark from "./containers/Bookmark/Bookmark";
import Profile from "./containers/StudentProfile/StudentProfilev2";
import ChangePassword from "./containers/Authentication/changePassword";
import PageNotFound from "./containers/PageNotFound/PageNotFound";

import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import './App.css';

import axios from "axios";




axios.defaults.baseURL = "http://127.0.0.1:8000";

// function requireAuth(nextState, replace, next) {
//   if (!authenticated) {
//     replace({
//       pathname: "/login",
//       state: {nextPathname: nextState.location.pathname}
//     });
//   }
//   next();
// }
// const fakeAuth = {
//   isAuthenticated: false,
//   signin(cb) {
//     fakeAuth.isAuthenticated = true;
//     setTimeout(cb, 100); // fake async
//   },
//   signout(cb) {
//     fakeAuth.isAuthenticated = false;
//     setTimeout(cb, 100);
//   }
// };


// const authContext = createContext();

// function ProvideAuth({ children }) {
//   const auth = useProvideAuth();
//   return (
//     <authContext.Provider value={auth}>
//       {children}
//     </authContext.Provider>
//   );
// }


// function useProvideAuth() {
//   const [user, setUser] = useState(null);

//   const signin = cb => {
//     return fakeAuth.signin(() => {
//       setUser("user");
//       cb();
//     });
//   };

//   const signout = cb => {
//     return fakeAuth.signout(() => {
//       setUser(null);
//       cb();
//     });
//   };

//   return {
//     user,
//     signin,
//     signout
//   };
// }


// function useAuth() {
//   return useContext(authContext);
// }

// function PrivateRoute({ children, ...rest }) {
//   let auth = useAuth();
//   return (
//     <Route
//       {...rest}
//       render={({ location }) =>
//         auth.user ? (
//           children
//         ) : (
//           <Redirect
//             to={{
//               pathname: "/login",
//               state: { from: location }
//             }}
//           />
//         )
//       }
//     />
//   );
// }




// -------------
class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      csrf: "",
      isAuthenticated: false,
      onBoarded: false
    }
  }

  // componentDidMount = () => {
  //   this.getSession();
  // }

  // getCSRF = () => {
  //   fetch("https://api.invictus.com.kh/api/csrf/", {
  //     credentials: "include",
  //   })
  //     .then((res) => {
  //       let csrfToken = res.headers.get("X-CSRFToken");
  //       this.setState({ csrf: csrfToken });
  //       console.log("csrfToken: ", csrfToken);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // }

  // // check if user is authenticated 
  // getSession = () => {
  //   fetch("https://api.invictus.com.kh/api/session/", {
  //     credentials: "include",
  //   })
  //     .then((res) => res.json())
  //     .then((data) => {
  //       console.log(data);
  //       if (data.isAuthenticated) {
  //         this.setState({ isAuthenticated: true });
  //       } else {
  //         this.setState({ isAuthenticated: false });
  //         this.getCSRF();
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // }

  // fetchMe = () => {
  //   axios.get(`https://api.invictus.com.kh/api/me`,
  //     { withCredentials: true })
  //     .then(response => {
  //       console.log("response.data.profile.interest_1: ", response.data.profile.interest_1)
  //       // console.log("response null? : ",response.data.profile.interest_1===null)
  //       if (response.data.profile.interest_1 == null) {
  //         console.log("else setState false")
  //         // this.setState({ onBoarded: false }, () => {
  //         //     console.log("onBoarded false: ", this.state.onBoarded)
  //         // })
  //         this.setOnboarded(false)
  //       } else {
  //         console.log("else setState true")
  //         // this.setState({ onBoarded: true }, () => {
  //         //     console.log("onboard true: ", this.state.onBoarded)
  //         // })
  //         this.setOnboarded(true)
  //       }
  //       console.log("onboarded(state): ", this.state.onBoarded)
  //       console.log("response.data: ", response.data)
  //       return response.data
  //     })
  //     .catch(error => {
  //       console.error('Error', error);
  //     });
  // }





  render() {
    return (

      <Router>
        <div>
          <Switch>
            <Route path="/login/" component={Login} />
            <Route path="/change-password" component={ChangePassword} />
            <Route path="/onboarding" component={Onboarding} />
            <Route path="/undergraduate-programmes/compare" component={CourseCompare} />
            <Route path="/undergraduate-programmes/:id" component={CourseDetails} />
            <Route path="/undergraduate-programmes" component={Courses} />
            <Route path="/careers" component={Careers} />
            <Route path="/career-domains/compare" component={CareerCompare} />
            <Route path="/career-domains/:id" component={CareerDetails} />
            <Route path="/career-domains" component={Careers} />
            <Route path="/events" component={Events} />
            <Route path="/design-thinking" component={DesignThinking} />
            <Route path="/project-outline" component={DTProjectOutline} />
            <Route path="/lesson-materials" component={DTLessonMaterials} />
            <Route path="/fablab" component={Fablab} />
            <Route path="/curated-projects" component={FabCuratedProjects} />
            <Route path="/fablab-lesson-materials" component={FabLessonMaterials} />
            <Route path="/ipq" component={IPQ} />
            <Route path="/bookmark" component={Bookmark} />
            <Route path="/resources" component={Courses} />
            <Route path="/profile" component={Profile} />
            <Route exact path="/" component={Home} />
            <Route path="*" component={PageNotFound} />
          </Switch>
        </div>
      </Router>
    );
  }
}

class App_ extends Component {
  constructor() {
    super();
    this.state = {
      // name: "React",
      isAuthenticated: true
    };
  }

  render() {
    return (
      <div>
        <Router>
          <div>
            {/* <ul>
              <li>
                <Link to="/home">Home</Link>
              </li>
              <li>
                <Link to="/test1">Test 1</Link>
              </li>
              <li>
                <Link to="/test2">Test 2</Link>
              </li>
              <li>
                <Link to="/test3">Test 3</Link>
              </li>
            </ul> */}
            {/* <hr /> */}
            <Switch>
              <Route
                exact
                path="/"
                render={() => {
                  return (
                    this.state.isAuthenticated ?
                      <Redirect to="/home" /> :
                      <Redirect to="/test1" />
                  )
                }}
              />
              <Route exact path="/home" component={Home} />
              <Route exact path="/login" component={Login} />
              <Route exact path="/undergraduate-programmes/:id" component={CourseDetails} />
              <Route exact path="/undergraduate-programmes" component={Courses} />
              <Route exact path="/career-domains/:id" component={Careers} />
              <Route exact path="/career-domains" component={Careers} />
            </Switch>
          </div>
        </Router>
      </div>
    );
  }
}






export default App;
