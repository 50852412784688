import React, { Component, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import axios from "axios";

import '../UndergraduateProgrammes/UndergraduateProgrammes.css'
import ResponsiveAppBar from '../../components/navbar'
import AppFooter from '../../components/footer';
import BookmarkListings from '../../components/cards/BookmarkListings';
import Recommender from '../../components/cards/BookmarkRecommender';



class Bookmark extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedUniversity: [],
            selectedDiscipline: [],
            search: "",
            courses_: [],
            page: 1,
            pageCount: 0,
            total: null,
            loading: false
        };

        this.setCourses_ = this.setCourses_.bind(this);
    }

    componentDidMount() {
        this.fetchCourses();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.page !== this.state.page) {
            this.fetchCourses();
        }
    }

    setSelectedUniversity = (data) => {
        this.setState({ selectedUniversity: data })
    }

    setSelectedDiscipline = (data) => {
        this.setState({ selectedDiscipline: data })
    }

    setSearchQuery = (data) => {
        this.setState({ search: data.target.value })
    }

    setCourses_ = (data) => {
        this.setState({ courses_: data })
    }

    setPage = (data) => {
        this.setState({ page: data })
    }

    setPageCount = (data) => {
        this.setState({ pageCount: data })
    }

    setTotal = (data) => {
        this.setState({ total: data })
    }

    setLoading = (data) => {
        this.setState({ loading: data })
    }

    getUrlQuery = () => {
        let uniFilter = ""
        for (let i = 0; i < this.state.selectedUniversity.length; i++) {
            uniFilter += `&university=${this.state.selectedUniversity[i].value}`;
        }

        let disciplineFilter = ""
        for (let i = 0; i < this.state.selectedDiscipline.length; i++) {
            disciplineFilter += `&discipline=${this.state.selectedDiscipline[i].value}`;
        }
        return `https://api.invictus.com.kh/api/undergraduate-programmes?search=${this.state.search}&page=${this.state.page}${uniFilter}${disciplineFilter}`;
    }

    resetForm = () => {
        this.setState({
            ...this.state,
            selectedUniversity: [],
            selectedDiscipline: [],
            search: ""
        }, () => {
            this.fetchCourses();
        })
    }

    fetchCourses = () => {
        const URL = this.getUrlQuery();
        axios.get(URL, { withCredentials: true })
            .then(response => {
                this.setCourses_(response.data.results)
                let contentRange = response.headers["content-range"]
                let total = contentRange.split("/")[1]
                let length = response.data.page_size
                const total_page = Math.ceil(total / length)
                this.setPageCount(total_page)
                this.setTotal(contentRange.split("/")[1])
            })
            .catch(error =>
                console.error('Error', error));
    }


    render() {
        return (
            <Container fluid mx="3px" id="courses" style={{ width: "100%", margin: 0, padding: 0 }}>
                <ResponsiveAppBar />

                <div className="flex-container" id="course-header">
                    <h1 style={{ fontSize: "25px" }}>My Bookmark</h1>
                </div>
                <div className="container">
                    <div className="subtitile"
                        style={{ fontStyle: "italic", paddingTop: "1.5em", fontSize: "16px", fontFamily: "Poppins" }}>
                        <p style={{ textAlign: "left" }}>Hi! Here's Your Bookmark</p>
                    </div>
                    <BookmarkListings />
                    {/* <Recommender /> */}
                </div>
                <AppFooter />
            </Container>
        )
    }
}

export default Bookmark;