import * as React from 'react';
import { MDBCard, MDBCardImage, MDBCardBody, MDBCardTitle, MDBCardSubTitle, MDBCardText, MDBRow, MDBCol, MDBBtn } from 'mdb-react-ui-kit';
import { Container, Button } from 'react-bootstrap';
import { Checkbox } from '@mui/material';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import BookmarkIcon from '@mui/icons-material/Bookmark';

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
const BASE_API_URL = `https://api.invictus.com.kh/api`;



export default function RelevantUndergrad() {
    return (
        <Container>
            <div className="relevant_careers" style={{ marginTop: '2em' }}>
                <h5 style={{ fontSize: '17px' }}>
                    Here are some of the relavent undergraduate programmes that you might be interested in!
                </h5>
            </div>
            <MDBRow className='row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 g-4 relative transform-container' style={{ justifyContent: 'center' }}>
                <MDBCol>
                    <MDBCard className='h-100'>
                        <MDBCardImage
                            src='https://www.ntu.edu.sg/images/default-source/admissions/microsoftteams-image-(44)aa92ec64-ec84-468b-9ff2-23692f373bfd.png?sfvrsn=f6d76307_3'
                            // src={BASE_API_URL + course.picture}
                            alt='...'
                            position='top'
                        />
                        <MDBCardBody>
                            <MDBRow className="course-card-title">
                                <MDBCol className="col-9">
                                    <MDBCardTitle>Bachelor of Engineering in Bioengineering</MDBCardTitle>
                                </MDBCol>

                                <MDBCol className="col-3">
                                    <Checkbox
                                        {...label}
                                        icon={<BookmarkBorderIcon />}
                                        checkedIcon={<BookmarkIcon />}
                                    // value={course.id}
                                    // checked={course.bookmarked}
                                    // onChange={(event) => addBookmark(event, course.id)}
                                    />
                                </MDBCol>
                            </MDBRow>

                            <MDBCardSubTitle>NATIONAL TECHNOLOGICAL UNIVERSITY | SCHOOL OF MECHANICAL AND AEROSHPACE ENGINEERING</MDBCardSubTitle>
                            <MDBCardText style={{ color: "black", fontSize: "11px" }}>
                                The School offers a four-year undergraduate degree programme in Bioengineering (BIE).
                                Upon graduation, successful students will be awarded direct honours.
                            </MDBCardText>


                            <MDBRow style={{ display: "flex", justifyContent: "center" }}>
                                <div className="col-6 card__actions">
                                    <Button href={`/undergraduate-programmes/39`} className="btn btn-color-details" style={{ fontSize: "10px" }}>Details</Button>
                                </div>
                            </MDBRow>
                        </MDBCardBody>
                    </MDBCard>
                </MDBCol>

                <MDBCol>
                    <MDBCard className='h-100'>
                        <MDBCardImage
                            src='https://images.unsplash.com/photo-1518314916381-77a37c2a49ae?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTB8fHJvYm90aWN8ZW58MHx8MHx8&auto=format&fit=crop&w=600&q=60'
                            // src={BASE_API_URL + course.picture}
                            alt='...'
                            position='top'
                        />
                        <MDBCardBody>
                            <MDBRow className="course-card-title">
                                <MDBCol className="col-9">
                                    <MDBCardTitle>Robotic Systems</MDBCardTitle>
                                </MDBCol>

                                <MDBCol className="col-3">
                                    <Checkbox
                                        {...label}
                                        icon={<BookmarkBorderIcon />}
                                        checkedIcon={<BookmarkIcon />}
                                    // value={course.id}
                                    // checked={course.bookmarked}
                                    // onChange={(event) => addBookmark(event, course.id)}
                                    />
                                </MDBCol>
                            </MDBRow>

                            <MDBCardSubTitle>SINGAPORE INSTITUTE OF TECHNOLOGY | ENGINEERING</MDBCardSubTitle>
                            <MDBCardText style={{ color: "black", fontSize: "11px" }}>
                                "The Bachelor of Engineering with Honours in Robotics Systems is a four-year,
                                direct honours degree programme that focuses on the design and development of service/field robotic systems.
                            </MDBCardText>


                            <MDBRow style={{ display: "flex", justifyContent: "center" }}>
                                <div className="col-6 card__actions">
                                    <Button href={`/undergraduate-programmes/141`} className="btn btn-color-details" style={{ fontSize: "10px" }}>Details</Button>
                                </div>
                            </MDBRow>
                        </MDBCardBody>
                    </MDBCard>
                </MDBCol>

                <MDBCol>
                    <MDBCard className='h-100'>
                        <MDBCardImage
                            src='https://www.ntu.edu.sg/images/default-source/hub-programmes/sbs-bs1fb705e9-7c74-42b2-8d83-1ad4177a6617.jpg?sfvrsn=7f788224_3'
                            // src={BASE_API_URL + course.picture}
                            alt='...'
                            position='top'
                        />
                        <MDBCardBody>
                            <MDBRow className="course-card-title">
                                <MDBCol className="col-9">
                                    <MDBCardTitle>Bachelor of Science in Biological Sciences</MDBCardTitle>
                                </MDBCol>

                                <MDBCol className="col-3">
                                    <Checkbox
                                        {...label}
                                        icon={<BookmarkBorderIcon />}
                                        checkedIcon={<BookmarkIcon />}
                                    // value={course.id}
                                    // checked={course.bookmarked}
                                    // onChange={(event) => addBookmark(event, course.id)}
                                    />
                                </MDBCol>
                            </MDBRow>

                            <MDBCardSubTitle>NATIONAL TECHNOLOGICAL UNIVERSITY | SCHOOL OF BIOLOGICAL SCIENCES</MDBCardSubTitle>
                            <MDBCardText style={{ color: "black", fontSize: "11px" }}>
                                This 4-year degree offers broad-based training in biological sciences and prepares graduates for challenging careers in
                                Life Sciences research and industry.
                            </MDBCardText>


                            <MDBRow style={{ display: "flex", justifyContent: "center" }}>
                                <div className="col-6 card__actions">
                                    <Button href={`/undergraduate-programmes/52`} className="btn btn-color-details" style={{ fontSize: "10px" }}>Details</Button>
                                </div>
                            </MDBRow>
                        </MDBCardBody>
                    </MDBCard>
                </MDBCol>
            </MDBRow>

            <div className="btn-more" style={{ justifyContent: 'center', display: 'flex' }}>
                <Button variant="outline" href={`/undergraduate-programmes`} style={{ justifyContent: 'center', display: 'flex', margin: '2em', fontSize: '13px', borderColor: '#053e85' }}>
                    See More
                </Button>
            </div>
        </Container>
    )
}
