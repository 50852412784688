import React, { Component } from 'react';
import { Container } from 'react-bootstrap';
import ResponsiveAppBar from '../../components/navbar';
import FablabNavTabs from '../../components/menu_bar/fablab-menu';
import AppFooter from '../../components/footer';

import './Fablab.css';

class Fablab extends Component {
    render() {
        return (
            <Container fluid mx="3px" id="courses" style={{ width: "100%", margin: 0, padding: 0 }}>
                <ResponsiveAppBar />

                <div className="flex-container align-content-sm-stretch" id="dt-header">
                    <h1 style={{ fontSize: "25px", paddingTop: "12px", textAlign: "center" }}>Fablab</h1>

                    <div className="row dt-subtitle"
                        style={{ fontSize: "19px", fontFamily: "Open Sans", background: "transparent" }}>
                        <p style={{ textAlign: "center", fontSize: "18px" }}>Speacially brought to you by Invictus</p>
                    </div>

                    <FablabNavTabs />

                </div>
                <div className="container">
                    <div className="overview" style={{ margin: "1em" }}>
                        <h1 style={{ textAlign: "left", alignContent: "left", fontSize: "20px" }}>
                            What is Fablab?
                        </h1>

                        <div className="dt-pic" style={{ alignSelf: "center", justifyContent: "center", display: "flex" }}>
                            <img className="design-thinking" src='https://fabacademy.org/labs/Singapore.jpg'></img>
                        </div>

                        <p style={{ textAlign: "left", marginTop: '2em' }}>
                            A Fab Lab is a digital fabrication laboratory is a space for research and innovation.
                            To achieve the goal of being a one-stop location for our students to learn, practice and master digital fabrication and prototyping
                            - the Invictus Fablab will house cutting edge equipment such as Laser Cutters, 3D Printers, Drilling and Moulding machines,
                            amongst other tools to help facilitate rapid iteration and refinement of prototypes.
                        </p>
                        <p style={{ textAlign: "left" }}>
                            Students will be part of an impressive global network of Fablabs with access to exclusive Fablab resources and mentors.
                            They get to experience a unique curriculum designed to develop and hone their critical thinking, analytical, and problem-solving skills through
                            research projects which give them crucial opportunities to design, innovate and make their prototypes a realty.
                            There is a wide repertoire of research projects for students to embark on depending on their interests and strengths, carving out a niche area
                            for themselves and be part of a global team of future-ready lifelong learners, creators and advocates in helping to develop sustainable solutions for
                            real-world challenges.
                        </p>
                    </div>


                </div>
                <AppFooter />
            </Container>
        )
    }
}

export default Fablab;