import axios from "axios";


export const sectorOptions = [
    { value: 'Engineering', label: 'Engineering' },
    { value: 'Healthcare', label: 'Healthcare' },
    { value: 'Business', label: 'Business' },
    { value: 'IT & Digital', label: 'IT & Digital' },
    { value: 'Science & Maths', label: 'Science & Maths' },
    { value: 'Construction & Built Environment', label: 'Construction & Built Environment' },
];

export let disciplines = []
export let universities = []

const changeKeyObjects = (arr, replaceKeys) => {
    return arr.map(item => {
      const newItem = {};
      Object.keys(item).forEach(key => {
        newItem[replaceKeys[key]] = item[[key]];
      });
      return newItem;
    });
  };

export const GetDisciplines = async () => {
    await axios.get(`https://api.invictus.com.kh/api/disciplines`, { withCredentials: true })
        .then(response => {
            const disciplines_array = response.data
            const replaceKeys = { id: "value", name: "label" };
            //console.log("disciplines_array",disciplines_array)
            disciplines = changeKeyObjects(disciplines_array, replaceKeys);
            //console.log("disciplines: ",disciplines);
            return disciplines
        })
        .catch(error =>
            console.error("Error ", error));
};

export const GetUniversity = async () => {
    await axios.get(`https://api.invictus.com.kh/api/universities`, { withCredentials: true })
        .then(response => {
            const university_array = response.data
            const replaceKeys_uni = { id: "value", name: "label" };
            //console.log("response.data (universityArray): ",university_array)
            universities = changeKeyObjects(university_array, replaceKeys_uni);
            //console.log("unis: ",universities);
            return universities
        })
        .catch(error =>
            console.error("Error ", error));
}

