import React, { Component } from "react";

import Page404 from '../../assets/undraw_page_not_found_re_e9o6.svg'

class PageNotFound extends Component {
  render() {
    return (
      <div id="wrapper" style={{ paddingTop: "3em" }}>
        <div className="row" style={{ display: "flex", justifyContent: "center" }}>
          <img className="page404" src={Page404} style={{ width: "35%" }} />
        </div>
        <div id="info" style={{ marginTop: "2em" }}>
          <h3 style={{ fontFamily: "Open Sans",textAlign:"center", fontSize:"medium" }}>
            Opps! This page could not be found 😔 
            </h3>
        </div>
      </div >
    );
  }
}
export default PageNotFound;
