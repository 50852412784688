import React, { Component } from "react";
import { Container } from "react-bootstrap";
import Button from 'react-bootstrap/Button'
import { height } from "@mui/system";

import RSCAREA from '../../assets/RSCAREA logo.png'
import Welcome from '../../assets/home-welcome.svg'
import './Home.css'



class Home extends Component {
  render() {
    return (
      // <Container className="p-0 background-image" fluid={true}> 
      <div className="wrapper ">
        <div className="row">
          <div className="col-sm-12 col-md-6">
            <div className="row" style={{ display: "flex", justifyContent: "center", paddingTop: "2.5em", textAlign: "center" }}>
            <img className="page404" src="https://igsloto.ca7dev.url3.net/skin/custom/igsloto.ca7dev.url3.net/ca7/entry/ivtg_logo.png" style={{ width: "35%" }} />
              {/* <img className="page404" src={RSCAREA} style={{ width: "35%" }} /> */}
              <h2 style={{ fontFamily: "Poppins", textAlign: "center", paddingTop: "1.5em" }}>Welcome to  Invictus Global Schoolhouse!</h2>
              <div className="sub-desc" style={{ paddingTop: "1.5em", textAlign: "left", width: "60%" }}>
                <h4>Regional Student Career and Education Advisor</h4>
                <p style={{ textAlign: "left", paddingTop: "2em", fontStyle: "italic" }}>Explore more possiblities and get closer to your dreams!</p>
              </div>
            </div>
          </div>

          <div className="col-sm-12 col-md-6" style={{ alignItems: "right", textAlign: "center" }}>
            {/* <div className="row" style={{ display: "flex", justifyContent: "right" }}> */}
            <img className="welcome" src={Welcome} style={{ height: "auto", width: "75%", float: "center", paddingTop: "2.5em" }} />
            {/* </div> */}
          </div>

          <div className="bg col-6" id="get-start" size="sm" style={{ textAlign: "center", display: "inline-block", bottom: "100px" }}>
            <Button className="home-button" href="/login/" >
              Get Started
            </Button>
          </div>
        </div>
      </div>
      // </Container>
    );
  }
}
export default Home;
