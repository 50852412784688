import * as React from 'react';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Link } from 'react-router-dom';

function LinkTab(props) {
    return (
        <Tab
            component="a"
            onClick={(event) => {
                event.preventDefault();
            }}
            {...props}
        />
    );
}

export default function IPQNavTabs() {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Box sx={{ width: '100%' }} style={{ background: 'transparent', boxShadow: 'none', color: 'white', justifyContent: "center" }} elevation={0}>
            <Tabs value={value} onChange={handleChange} aria-label="nav tabs example" textColor="white" indicatorColor="white" TabIndicatorProps={{style: {background:'white'}}} centered>
                <Tab component={Link} label="Overview" to="/ipq" />
                <Tab component={Link} label="Project Outline" />
                <Tab component={Link} label="Assesment Criteria" />
            </Tabs>
        </Box>
    );
}
