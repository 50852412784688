import React, { Component } from 'react';
import { Col, Container, Row, Card, Button, Badge } from 'react-bootstrap';
import { MDBContainer } from "mdbreact";
import { MDBRow, MDBCol } from 'mdb-react-ui-kit';
import axios from 'axios';

import '../UndergraduateProgrammes/UndergradDetails.css'
// import './UndergradDetails.css'
import ResponsiveAppBar from '../../components/navbar'
import AppFooter from '../../components/footer';


class CareerCompare extends Component {
    constructor(props) {
        super(props);
        this.state = {
            compareCareer1: null,
            compareCareer2: null,
            careerDetails: [],
            riasec_1: "",
            riasec_2: "",
            riasec_3: "",
        }
    }
    setRiasec_1 = (data) => {
        this.setState({ riasec_1: data })
    }

    setRiasec_2 = (data) => {
        this.setState({ riasec_2: data })
    }

    setRiasec_3 = (data) => {
        this.setState({ riasec_3: data })
    }

    setCompareCareer1 = (data) => {
        this.setState({ compareCareer1: data })
    }

    setCompareCareer2 = (data) => {
        this.setState({ compareCareer2: data })
    }

    setCareerDetail = (data) => {
        this.setState({ careerDetails: data })
    }

    retrieveCareerCompareID = () => {
        let splitString = [];

        if (localStorage.getItem('careers_compareID')) {
            const career_compareID = localStorage.getItem('careers_compareID')
            console.log("tyoeOf", typeof localStorage.getItem('careers_compareID'))
            splitString = career_compareID.split(",")
            console.log("compareCourse1: ", splitString[0])
            console.log("compareCourse2: ", splitString[1])

            this.setCompareCareer1(splitString[0])
            this.setCompareCareer2(splitString[1])
        }
        console.log("compare1: ", this.state.compareCareer1)
        console.log("compare2: ", this.state.compareCareer2)

        return `https://api.invictus.com.kh/api/career-domains?compare=${splitString[0]},${splitString[1]}`
    }

    componentDidMount = () => {
        this.fetchCareerDetails();
    }

    fetchMe = () => {
        axios.get(`https://api.invictus.com.kh/api/me`,
            { withCredentials: true })
            .then(response => {
                this.setRiasec_1(response.data.profile.interest_1.name)
                this.setRiasec_2(response.data.profile.interest_2.name)
                this.setRiasec_3(response.data.profile.interest_3.name)
                console.log(response.data)
                return response.data
            })
            .catch(error => {
                console.error('Error', error);
            });
    }


    fetchCareerDetails = () => {
        const url = this.retrieveCareerCompareID()
        console.log(url)

        axios.get(url,
            { withCredentials: true })
            .then(response => {
                console.log("response.data", response.data.skills)
                this.fetchMe();
                this.setCareerDetail(response.data)
                return response.data
            })
            .catch(error => {
                console.error('Error', error);
            });
    }

    handleClickBack = e => {
        this.props.history.push(`/career-domains`);
    };

    render() {
        return (
            <>
                <Container fluid mx="3px" id="courses" style={{ width: "100%", margin: 0, padding: 0 }}>
                    <ResponsiveAppBar />
                    <div className="flex-container" id="course-header">
                        <h1 style={{ fontSize: "25px" }}>Careers Comparison</h1>
                        <div className="career-subheader" style={{ backgroundColor: "transparent" }}>
                            <h4 style={{ fontSize: "18px", fontFamily: "Poppins Light" }}>Your choice matters</h4>
                        </div>
                    </div>

                    <Container>
                        <Row>
                            <button className="btn" id="back-button" style={{ fontSize: "14px", marginTop: "20px", paddingLeft: 0, marginBottom: "15px", textAlign: "left" }}
                                onClick={this.handleClickBack}>
                                <i class="bi bi-arrow-return-left fa-lg"></i> Back
                            </button>

                            {this.state.careerDetails.map(career => (
                                <Col className="border-right sm-12 md-6" key={career.id} style={{ marginLeft: "30px", paddingRight: "20px" }}>
                                    <Card className="compare-title" style={{ paddingTop: "10px", paddingBottom: "10px" }}>
                                        <Card.Body className="font-weight-bold" style={{ textAlign: "center", fontSize: "18px" }}>{career.display_name}</Card.Body>
                                    </Card>

                                    <div className="details" id="basicInformation">
                                        <h2 style={{ fontSize: "19px" }}>Basic Information</h2>
                                        <MDBRow className="details-header">
                                            <MDBCol className="col-12 col-md-4  detail-title">
                                                <h5>Career Domain Title</h5>
                                            </MDBCol>
                                            <MDBCol className="col-12 col-md-8 detail">
                                                <p> {career.display_name}</p>
                                            </MDBCol>
                                        </MDBRow>

                                        <MDBRow className="details-header">
                                            <MDBCol className="col-12 col-md-4  detail-title">
                                                <h5>Career Domain Description</h5>
                                            </MDBCol>
                                            <MDBCol className="col-12 col-md-8 detail">
                                                <p>{career.description}</p>
                                            </MDBCol>
                                        </MDBRow>

                                        <MDBRow className="details-header">
                                            <MDBCol className="col-12 col-md-4  detail-title">
                                                <h5>Summary</h5>
                                            </MDBCol>
                                            <MDBCol className="col-12 col-md-8 detail">
                                                <p>{career.summary}</p>
                                            </MDBCol>
                                        </MDBRow>
                                    </div>

                                    <div className="details" id="interest">
                                        <h2 style={{ fontSize: "19px" }}>Interest Codes</h2>
                                        <p>
                                            <Badge className="btn btn-primary" bg="info" fontSize="14px">
                                                {career?.interest_1?.name}
                                            </Badge>
                                            <Badge className="btn btn-primary" bg="info" fontSize="14px">
                                                {career?.interest_2?.name}
                                            </Badge>
                                            <Badge className="btn btn-primary" bg="info" fontSize="14px">
                                                {career?.interest_3?.name}
                                            </Badge>
                                        </p>
                                    </div>


                                    <div className="details" id="admission">
                                        <h2 style={{ fontSize: "19px" }}>Skills</h2>
                                        <MDBRow className="details-header">
                                            <MDBContainer>
                                                {(career.skills).length > 0 && (career.skills).map(skill => (
                                                    <h7>
                                                        <Badge pill className='mx-2' bg="warning" text="dark" style={{ marginTop: "1em", paddingTop: "0.7em", paddingBottom: "0.7em", paddingLeft: "0.5em", paddingRight: "0.8em" }}>
                                                            {skill}
                                                        </Badge>
                                                    </h7>
                                                ))}
                                            </MDBContainer>
                                        </MDBRow>
                                    </div>

                                    <div className="details" id="employment">
                                        <h2 style={{ fontSize: "19px" }}>Job Titles</h2>
                                        <MDBRow className="details-header">
                                            <MDBCol className="col-12 col-md-8">
                                                {(career.job_titles).length > 0 && (career.job_titles).map(title => (
                                                    <h7>
                                                        <Badge className='mx-2 jobTitles'
                                                            style={{ marginTop: "1em", paddingTop: "1em", paddingBottom: "1em", paddingLeft: "0.5em", paddingRight: "0.8em", backgroundColor: "#1a3061", color: "rgb(249, 168, 38)" }}>
                                                            {title}
                                                        </Badge>
                                                    </h7>
                                                ))}
                                            </MDBCol>
                                        </MDBRow>
                                    </div>
                                </Col>
                            ))}
                        </Row>
                    </Container>
                    <AppFooter />
                </Container>
            </>
        )
    }
}


export default CareerCompare;