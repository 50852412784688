import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

export default function GoalCard() {
  return (
    <Card sx={{ maxWidth: 330 }}>
      <img src="https://img.icons8.com/color/48/000000/goal--v1.png"/> Your Career Goal
      <CardMedia
        component="img"
        height="300"
        image="https://api.invictus.com.kh/api/django_static/img/careers/26.png"
        alt="Career icon"
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
        Biomaterials Engineering
        </Typography>
        <Typography variant="body2" color="text.secondary">
        The combination of biomedical  and physical sciences to the adoption and adaption of materials to be used in conjuction with biological systems
        </Typography>
      </CardContent>
      <CardActions>
        <Button size="small" >Change Career Goal</Button>
       
      </CardActions>
    </Card>
  );
}
