import React from "react";


const SearchProgrammes = (props) => {
    const { setSearchQuery, search } = props

    return (
        <div>
            <div className="mb-3">
                <label className="form-label" style={{ fontSize: "13px" }}>Search</label>
                <input
                    id="searchFilter"
                    type="text"
                    className="form-control"
                    placeholder="Enter Programme Name"
                    value={search}
                    onChange={setSearchQuery}
                    label="Search Programme"
                    style={{ height: "38px", fontSize: "13px" }}
                />
            </div>
        </div>
    )
}


export default SearchProgrammes;