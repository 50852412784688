import axios from "axios";
import React, { Component, useEffect, useState } from 'react';
import { FormGroup, Card, Row, Col, Button } from "react-bootstrap";
import { Checkbox, FormControlLabel, Pagination, Container } from '@mui/material';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import { MDBCard, MDBCardImage, MDBCardBody, MDBCardTitle, MDBCardText, MDBRow, MDBCol, MDBBtn } from 'mdb-react-ui-kit';
import Badge from 'react-bootstrap/Badge'

import './cards.css';
import NotFound from '../../assets/NotFound.svg'

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
const BASE_API_URL = `https://api.invictus.com.kh/api`

const CompareBottomSheet = (props) => {
    const { compareCareers } = props

    return (<div className="comparison-list-wrapper stack-top overlay" style={{ paddingBottom: "1em", width: "100%" }}>
        <Container>
            <Row>
                <div className="comparison-list-title-career">
                    <h4 style={{ fontSize: "14px" }}>You may choose and compare up to maximum 2 careers</h4>
                </div>
            </Row>

            <div className="row">
                {compareCareers.length > 0 && compareCareers.map((career) => {
                    console.log("career.id", career.image)
                    return (
                        <div className="col-md-5">
                            <Card style={{ marginTop: "1em" }}>
                                <Card.Body key={career.id}>
                                    <Card.Title style={{ fontSize: "13px" }}>{career.display_name}</Card.Title>
                                </Card.Body>
                            </Card>
                        </div>
                    );
                })}

                {compareCareers.length > 1 ?
                    <div className="col-md-2" style={{ marginTop: "2em", top: "10px" }}>
                        <Button id="compare-btn" href="/career-domains/compare" size="sm">Compare Now</Button>
                    </div> : null}
            </div>
        </Container>
    </div>
    )
}

const CareerListings = (props) => {
    const { search, careers, setCareers, page, setPage, pageCount } = props
    const storedValueAsNumber = Number(localStorage.getItem("careers_compareID"))
    const [selectedCompare, setSelectedCompare] = useState(
        Number.isInteger(storedValueAsNumber) ? storedValueAsNumber : []);
    const [checked, setChecked] = useState([]);
    const [compareCareers, setCompareCareers] = useState([]);
    const [cardChecked, setCardChecked] = useState(false);
    const [showCompareWrapper, setShowCompareWrapper] = useState(false)
    const [csrf, setCSRF] = useState("")
    const [auth, setAuth] = useState(false)
    const [riasec, setRiasec] = useState([])
    const selected = []

    axios.defaults.withCredentials = true


    useEffect(() => {
        const storedSelectedOption = localStorage.getItem('careers_compareID')
        let selectedList = []
        if (storedSelectedOption) {
            selectedList = storedSelectedOption.split(",").map(c => parseInt(c))

            // requests to be sent
            const requests = []
            selectedList.forEach(id => {
                requests.push(fetchCareerbyID(id))
            })
            axios.all(requests)
                .then(axios.spread((...responses) => {
                    //console.log("responses", ...responses)
                    setCompareCareers([...responses])
                }))
        } else {
            localStorage.setItem("careers_compareID", "")
        }
        setCardChecked(selectedList)
        setChecked(selectedList)
    }, [])

    useEffect(() => {
        getSession();
    }, [])

    const getCSRF = () => {
        fetch("https://api.invictus.com.kh/api/csrf/", {
            credentials: "include",
        })
            .then((res) => {
                let csrfToken = res.headers.get("X-CSRFToken");
                setCSRF(csrfToken);
                //console.log("csrfToken: ", csrfToken);
            })
            .catch((err) => {
                console.log(err);
            });
    }

    // check if user is authenticated 
    const getSession = () => {
        fetch("https://api.invictus.com.kh/api/session/", {
            credentials: "include",
        })
            .then((res) => res.json())
            .then((data) => {
                console.log(data);
                if (data.isAuthenticated) {
                    setAuth({ isAuthenticated: true });
                    getCSRF();
                } else {
                    setAuth({ isAuthenticated: false });
                    window.location = "/login/"
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }


    const fetchMe = () => {
        axios.get(`https://api.invictus.com.kh/api/me`,
            { withCredentials: true })
            .then(response => {
                console.log("response.data.profile.interest_1: ", response.data.profile.interest_1)
                // console.log("response null? : ",response.data.profile.interest_1===null)
                console.log("onboarded data: ", response.data.onboarded)
                this.setOnboarding(response.data.onboarded)

                console.log("onboarded(state): ", this.state.onBoarded)
                console.log("response.data: ", response.data)
                return response.data
            })
            .catch(error => {
                console.error('Error', error);
            });
    }


    const fetchCareerbyID = (id) => {
        return axios.get(`${BASE_API_URL}/career-domains/${id}`).then(response => {
            console.log("response.data: ", response.data)
            return response.data
        });
    }

    const handleCompare = (event, career_id) => {
        var updatedList = [...checked];
        var updatedCompareCareers = [];

        if (event.target.checked) {
            updatedList = [...checked, career_id];
            updatedCompareCareers = [...compareCareers, careers.find(c => c.id == career_id)];
            console.log("updatedCompareCareers: ", updatedCompareCareers)
        } else {
            updatedList.splice(checked.indexOf(career_id), 1)
            updatedCompareCareers = compareCareers.filter(career => { console.log(`career_id ${career_id} - career.id ${career.id}`); return career.id !== career_id })
        }
        setCompareCareers(updatedCompareCareers)
        setChecked(updatedList, () => { console.log(checked) });
    }

    useEffect(() => {
        localStorage.setItem("careers_compareID", checked.toString())
        //console.log("useEffect: ", checked.toString())
        //console.log("localstorage: ", localStorage.getItem("careers_compareID"))
        if (checked.length > 0) {
            checked.forEach(id => {
            })
            //console.log(compareCareers)
            setShowCompareWrapper(true)
        } else {
            setShowCompareWrapper(false)
        }
    }, [checked]);

    const isDisabled = (career_id) => {
        return (
            checked.length > 1 && checked.indexOf(career_id) === -1
        );
    }

    const handleCardCheck = (event) => {
        if (event.target.checked) {
            setCardChecked(true)
        }
    }

    useEffect(() => {
        //console.log("cardChecked", cardChecked)
    }, [cardChecked])

    const addCareerBookmark = (event, career_id) => {
        let bookmarkAction = event.target.checked ? "bookmark" : "unbookmark"
        console.log("event.target.c: ", event.target.checked)
        const data = {
            action: bookmarkAction,
            career_domain_id: career_id
        }

        const config = {
            headers: { "X-CSRFToken": csrf },
            withCredentials: true,
        }

        return (
            axios.post(`${BASE_API_URL}/career-domains/bookmark`, data, config)
                .then(response => {
                    let career_idx = careers.findIndex(c => c.id === career_id);
                    console.log("career_idx:", career_idx)
                    careers[career_idx].bookmarked = !careers[career_idx].bookmarked
                    setCareers([...careers])
                    return response.data
                })
                .catch(error =>
                    console.log("Error", error))
        )
    }

    return (
        <>
            <MDBRow className='row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 g-4 transform-container'>
                {careers.length > 0 && (
                    careers.map(career => (<MDBCol key={career.id}>
                        <MDBCard className='h-100'>
                            <MDBCardImage
                                className="career-img"
                                src={BASE_API_URL + career.image}
                                alt='...'
                                position='top'
                                style={{ width: 'auto', height: "40%", objectFit: "contain" }}
                            />
                            <MDBCardBody>
                                <MDBRow className="course-card-title">
                                    <MDBCol className="col-9" key={career.id}>
                                        <MDBCardTitle>{career.display_name}</MDBCardTitle>
                                    </MDBCol>

                                    <MDBCol className="col-3">
                                        <Checkbox
                                            {...label}
                                            icon={<BookmarkBorderIcon />}
                                            checkedIcon={<BookmarkIcon />}
                                            value={career.id}
                                            checked={career.bookmarked}
                                            onChange={(event) => addCareerBookmark(event, career.id)}
                                        />
                                    </MDBCol>
                                </MDBRow>

                                <MDBRow>
                                    <div className="career-similarity">
                                        {
                                            (() => {
                                                if (career.score >= 0.7)
                                                    return <Badge pill bg="success"> Highly Matched 🔥</Badge>
                                                if (career.score >= 0.35 && career.score < 0.7)
                                                    return <Badge pill bg="warning"> Moderately Matched ⭐</Badge>
                                            })()
                                        }
                                    </div>
                                </MDBRow>

                                <MDBCardText style={{ color: "black", fontSize: "11px" }}>
                                    {career.description}
                                </MDBCardText>

                                <MDBRow style={{ display: "flex", justifyContent: "flex-end" }}>
                                    <div className="col-6 card__actions">
                                        <Button href={`/career-domains/${career.id}`} className="btn btn-color-details" style={{ fontSize: "10px" }}>View</Button>
                                    </div>

                                    <div className="col-6 card__actions">
                                        <FormGroup style={{ borderBlockColor: "black" }}>
                                            <FormControlLabel control={<Checkbox />} value={career.id} checked={checked.includes(career.id)} onChange={(event) => handleCompare(event, career.id)} disabled={isDisabled(career.id)}
                                                style={{ fontSize: "11px" }} label="Compare" />
                                        </FormGroup>
                                    </div>
                                </MDBRow>
                            </MDBCardBody>
                        </MDBCard>
                    </MDBCol>)) //}

                )}
            </MDBRow>

            {careers.length < 1 ?
                <div id="wrapper" style={{ paddingTop: "0.5em" }}>
                    <div className="row" style={{ display: "flex", justifyContent: "center" }}>
                        <img className="page404" src={NotFound} style={{ width: "35%" }} />
                    </div>
                    <div id="info" style={{ marginTop: "1em" }}>
                        <h3 style={{ fontFamily: "Open Sans", textAlign: "center", fontSize: "medium" }}>
                            Opps! No Result Found 😔
                        </h3>
                    </div>
                </div > : null
            }

            <div style={{ display: "flex", justifyContent: "center", marginTop: "3rem", marginBottom: "4rem" }}>
                <Pagination count={pageCount} page={page} onChange={(event, value) => {
                    setPage(value)
                }} />
            </div>

            {showCompareWrapper ? <CompareBottomSheet compareCareers={compareCareers} /> : null}
        </>
    )
}

export default CareerListings;
