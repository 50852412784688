import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import CardMedia from "@mui/material/Grid";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import profilepic from "../../assets/27-Businesswoman-512.jpg";
import FormControl, { useFormControl } from '@mui/material/FormControl';

export default function Namecard() {
  return (
    <Grid container spacing={2} className="nameCard">
      <Grid item xs={3}>
        <Card sx={{ minWidth: 275 }}>
          <CardContent>
            <Avatar
              alt="student profile"
              src={profilepic}
              sx={{ width: 180, height: 190, ml: 11, mb: 3.5 }}
            />
            <Typography sx={{ fontSize: 20 }} gutterBottom>
              Joseph
            </Typography>

            <Typography sx={{ mb: 1 }} color="text.secondary">
              "Realistic , Investigative , Artistic"
            </Typography>
            {/* <Typography variant="body2">
                well meaning and kindly.
                <br />
                {'"showwwwww meeeeeeee"'}
                </Typography> */}
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={5}>
        <Card sx={{ minWidth: 275 }}>
          <CardContent>
          <Box
      component="form"
      sx={{
        '& .MuiTextField-root': { m: 3, width: '20ch', maxWidth: '70%'},
       }}
      noValidate
      autoComplete="off"
    >
            <div>
              <TextField
                id="standard-read-only-input"
                label="First Name"
                defaultValue="Joseph"
                InputProps={{
                  readOnly: true,
                }}
                variant="standard"
              />
              <TextField
                id="standard-read-only-input"
                label="Second Name"
                defaultValue="Anderson"
                InputProps={{
                  readOnly: true,
                }}
                variant="standard"
              />
               <TextField
                id="standard-read-only-input"
                label="Date of Birth"
                defaultValue="31 January 2003"
                InputProps={{
                  readOnly: true,
                }}
                variant="standard"
              />
              <TextField
                id="standard-read-only-input"
                label="Contact Number"
                defaultValue="+65 8123 4567"
                InputProps={{
                  readOnly: true,
                }}
                variant="standard"
              />
              <FormControl sx={{ width: '60ch' }}>
              <TextField fullWidth 
                id="standard-read-only-input"
                label="Email Address"
                defaultValue="josephanderson@gmail.com"
                InputProps={{
                  readOnly: true,
                }}
                variant="standard"
              />
              </FormControl>
            </div>
            </Box>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={4}>
        <Card sx={{ minWidth: 275 }}>
          <CardContent>
          <TextField fullWidth 
          id="filled-multiline-static"
          label="Short Description"
          multiline
          rows={11}
          defaultValue="I am ambitious and driven. I thrive on challenge and constantly set goals for myself, so I have something to strive toward. I’m not comfortable with settling, and I’m always looking for an opportunity to do better and achieve greatness. In my previous role, I was promoted three times in less than two years.”"
          variant="filled"
          InputProps={{
            readOnly: true,
          }}
        />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}
