import React, { Component } from 'react';
import { Container, Button } from 'react-bootstrap';
import ResponsiveAppBar from '../../components/navbar';
import FablabNavTabs from '../../components/menu_bar/fablab-menu';
import AppFooter from '../../components/footer';

import './Fablab.css';
import LessonMaterial1 from '../../assets/FablabLessonMaterial1.jpg';
import LessonMaterial2 from '../../assets/FablabLessonMaterial2.jpg';


class FabLessonMaterials extends Component {
    render() {
        return (
            <Container fluid mx="3px" id="courses" style={{ width: "100%", margin: 0, padding: 0 }}>
                <ResponsiveAppBar />

                <div className="flex-container align-content-sm-stretch" id="dt-header">
                    <h1 style={{ fontSize: "25px", paddingTop: "12px", textAlign: "center" }}>Fablab</h1>

                    <div className="row dt-subtitle"
                        style={{ fontSize: "19px", fontFamily: "Open Sans", background: "transparent" }}>
                        <p style={{ textAlign: "center", fontSize: "18px" }}>Speacially brought to you by Invictus</p>
                    </div>

                    <FablabNavTabs />
                </div>

                <div className="container justify-content-center">
                    <div className="card  mx-auto  mt-4" style={{ maxWidth: "700px", maxHeight: '180px', marginTop: '2em' }}>
                        <div className="row no-gutters">
                            <div className="col-md-4" style={{ objectFit: "fill", padding: 0 }}>
                                <img src={LessonMaterial1} class="img-fluid card-img" alt="lesson-materials" style={{ height: '180px' }}></img>
                            </div>
                            <div className="col-md-8" style={{ padding: 0 }}>

                                <div className="card-body">
                                    <h5 className="card-title" style={{ fontSize: "16px" }}>Introduction to 3D Printing</h5>
                                    <p className="card-text" style={{ fontSize: "11px" }}>
                                        To start exploring the world of 3D Printing, you should go through this lesson material to learn how to design your own 3D pokemon,
                                        and operate the machine. This material also outlines the procedures of booking Invictus FabLab's 3D Printers and the precautions that
                                        you should take while working with a 3D printer.
                                    </p>
                                    <div className="col text-right">
                                        <Button href='#' className="btn btn-color-details" style={{ fontSize: "10px" }}>Download</Button>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div className="card  mx-auto  mt-4" style={{ maxWidth: "700px", maxHeight: '180px', marginTop: '2em' }}>
                        <div className="row no-gutters">
                            <div className="col-md-4" style={{ objectFit: "fill", padding: 0 }}>
                                <img src={LessonMaterial2} class="img-fluid card-img" alt="lesson-materials" style={{ height: '180px' }}></img>
                            </div>

                            <div className="col-md-8" style={{ padding: 0 }}>
                                <div className="card-body">
                                    <h5 className="card-title" style={{ fontSize: "16px" }}>Fablab Safety Training</h5>
                                    <p className="card-text" style={{ fontSize: "11px" }}>
                                        Safety First!
                                        To access Invictus FabLab and its machineries, you should complete this safety training.
                                        Please approach your instructors to take the safety test.
                                    </p>
                                    <div className="col text-right">
                                        <Button href='#' className="btn btn-color-details" style={{ fontSize: "10px" }}>Download</Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <AppFooter />
            </Container>
        )
    }
}

export default FabLessonMaterials;