import React, { Component, useEffect, useState } from 'react';
import axios from "axios";
import { useParams, useHistory } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import { MDBRow, MDBCol } from 'mdb-react-ui-kit';
import Button from 'react-bootstrap/Button'
import RelevantCareers from './RelevantCareers';

import './ProgrammeDetails.css';


const url = 'https://api.invictus.com.kh/api'
const label = { inputProps: { 'aria-label': 'Checkbox demo' } };


const ProgrammeDetails = () => {
    const [course_detail, setCourseDetail] = useState([])
    const history = useHistory();
    let { id } = useParams();

    const fetchCourseDetail = () => {
        console.log(`inside fetch course detail- id: ${id}`)
        axios.get(`${url}/undergraduate-programmes/${id}`).then(response => {
            setCourseDetail(response.data)
            console.log("course details: ", response.data)
        });
    }


    useEffect(() => {
        fetchCourseDetail();
    }, []);

    return (
        <>
            <div className="flex-container" id="course-detail-header">
                <h1 style={{ fontSize: "25px", textAlign: "center" }}>{course_detail.name}</h1>
            </div>

            <div className="container">
                <button className="btn" id="back-button" style={{ fontSize: "14px", marginTop: "20px", paddingLeft: 0 }}
                    onClick={() => history.goBack()}>
                    <i class="bi bi-arrow-return-left fa-lg"></i> Back
                </button>

                <div className="details" id="basicInformation">
                    <h2 style={{ fontSize: "19px" }}>Basic Information</h2>
                    <MDBRow className="details-header">
                        <MDBCol className="col-12 col-md-4 detail-title">
                            <h5> University</h5>
                        </MDBCol>
                        <MDBCol className="col-12 col-md-8 detail">
                            <p> {course_detail.university}</p>
                        </MDBCol>
                    </MDBRow>

                    <MDBRow className="details-header">
                        <MDBCol className="col-12 col-md-4 detail-title">
                            <h5> Faculty</h5>
                        </MDBCol>
                        <MDBCol className="col-12 col-md-8 detail">
                            <p> {course_detail.faculty}</p>
                        </MDBCol>
                    </MDBRow>

                    <MDBRow className="details-header">
                        <MDBCol className="col-12 col-md-4 detail-title">
                            <h5> Discipline</h5>
                        </MDBCol>
                        <MDBCol className="col-12 col-md-8 detail">
                            <p> {course_detail.discipline}</p>
                        </MDBCol>
                    </MDBRow>

                    <MDBRow className="details-header">
                        <MDBCol className="col-12 col-md-4 detail-title">
                            <h5> Description</h5>
                        </MDBCol>
                        <MDBCol className="col-12 col-md-8 detail">
                            <p>{course_detail.description}</p>
                        </MDBCol>
                    </MDBRow>

                    <MDBRow className="details-header">
                        <MDBCol className="col-12 col-md-4 detail-title">
                            <h5> Course Duration</h5>
                        </MDBCol>
                        <MDBCol className="col-12 col-md-8 detail">
                            <p>3.5 - 4 Years</p>
                        </MDBCol>
                    </MDBRow>

                    <MDBRow className="details-header">
                        <MDBCol className="col-12 col-md-4 detail-title">
                            <h5> Programme Type</h5>
                        </MDBCol>
                        <MDBCol className="col-12 col-md-8 detail">
                            <p>{course_detail.programme_type}</p>
                        </MDBCol>
                    </MDBRow>
                </div>

                <div className="details" id="admission">
                    <h2 style={{ fontSize: "19px" }}>Admissions</h2>
                    <MDBRow className="details-header">
                        <MDBCol className="col-12 col-md-4 detail-title">
                            <h5> Indicate Grade Profile (IGP)</h5>
                            <h7> 10th Percentile</h7>
                        </MDBCol>
                        <MDBCol className="col-12 col-md-8 detail">
                            <p>{course_detail.igp_10th_percentile}</p>
                        </MDBCol>
                    </MDBRow>

                    <MDBRow className="details-header">
                        <MDBCol className="col-12 col-md-4 detail-title">
                            <h5>Indicate Grade Profile (IGP)</h5>
                            <h7>90th Percentile</h7>
                        </MDBCol>
                        <MDBCol className="col-12 col-md-8 detail">
                            <p>{course_detail.igp_90th_percentile}</p>
                        </MDBCol>
                    </MDBRow>

                    <MDBRow className="details-header">
                        <MDBCol className="col-12 col-md-4 detail-title">
                            <h5> Application Period</h5>
                        </MDBCol>
                        <MDBCol className="col-12 col-md-8 detail">
                            <p> 1 February 2021 - 21 February 2021</p>
                        </MDBCol>
                    </MDBRow>

                    <MDBRow className="details-header">
                        <MDBCol className="col-12 col-md-4 detail-title">
                            <h5>Tuition Fee with Tuition Grant</h5>
                            <h7>per Academic Year</h7>
                        </MDBCol>
                        <MDBCol className="col-12 col-md-8 detail">
                            <p>$ {course_detail.tuition_fee_tg}</p>
                        </MDBCol>
                    </MDBRow>
                </div>

                <div className="row buttons">
                    <div className="flex-box">
                        <Button className="moreInformation" size="sm" href={course_detail.homepage_url} style={{ fontSize: "12px" }}>
                            Bring Me for More Information
                        </Button>
                    </div>
                </div>

                <RelevantCareers />
            </div>
        </>
    )

}

export default ProgrammeDetails;
