import React, { Component } from 'react';
import { Container } from 'react-bootstrap';
import Button from '@mui/material/Button';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import ResponsiveAppBar from '../../components/navbar';
import FablabNavTabs from '../../components/menu_bar/fablab-menu';
import CuratedProjectsCard from '../../components/cards/CuratedProjects';
import AppFooter from '../../components/footer';

import './Fablab.css';

const theme = createTheme({
    palette: {
        primary: {
            main: '#1A2E5A',
            darker: '#053e85',
        },
    },
});


class FabCuratedProjects extends Component {
    render() {
        return (
            <Container fluid mx="3px" id="courses" style={{ width: "100%", margin: 0, padding: 0 }}>
                <ResponsiveAppBar />

                <div className="flex-container align-content-sm-stretch" id="dt-header">
                    <h1 style={{ fontSize: "25px", paddingTop: "12px", textAlign: "center" }}>Fablab</h1>

                    <div className="row dt-subtitle"
                        style={{ fontSize: "19px", fontFamily: "Open Sans", background: "transparent" }}>
                        <p style={{ textAlign: "center", fontSize: "18px" }}>Speacially brought to you by Invictus</p>
                    </div>

                    <FablabNavTabs />
                </div>

                <div className="container" >
                    <div className="overview" style={{ margin: "1em" }}>
                        <h1 style={{ textAlign: "left", alignContent: "left", fontSize: "20px", marginLeft: "5em" }}>
                            Curated Projects
                        </h1>
                    </div>

                    <CuratedProjectsCard />
                    <ThemeProvider theme={theme}>
                        <Button variant="outlined" href="https://projects.fablabs.io/" target="_blank" style={{ justifyContent: 'center', display: 'flex', margin: '4em' }}>
                            See More
                        </Button>
                    </ThemeProvider>
                </div>
                <AppFooter />
            </Container>
        )
    }
}

export default FabCuratedProjects;